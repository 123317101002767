import React from "react";
import TitleBox from "../../TitleBox/TitleBox";
import "./TermsBox.css";
import { useNavigate } from "react-router-dom";
import { useCallback } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { useMemo } from "react";
import { useState } from "react";
const TermsBox = () => {
	let navigate = useNavigate();
	const [dataShow, setDataShow] = useState([]);
	const [sliceNumber, setSliceNumber] = useState(2);

	const dataTarget = useMemo(() => {
		return () => [
			<div className='box flex-col justify-start items-start'>
				<h6> [1] المقدمة</h6>

				<p>
					1.1. تُقدّم{" "}
					<span className='font-bold'>
						مؤسسة منصّات المشاهير للتسويق الإلكتروني
					</span>{" "}
					المسجّلة في المملكة العربيّة السعوديّة ورقم السجل التجاري
					(4030445660)، وعنوانها: (المملكة العربيّة السعوديّة، مدينة جدة)،
					منصّات <span className='font-bold'>"المشاهير"</span> والتي تعمل كوسيط
					إلكتروني بين المؤثر والمشهور الذي يقدم الخدمات الإعلانيّة والتسويقيّة
					من ناحية وبين التاجر الذي يطلب الخدمات الإعلانيّة من المؤثرين
					المسجّلين في المنصّات والمُستخدِم النهائي الذين يطلب الإهداءات الصوتية
					أو المرئية، فمن خلالها يستطيع التاجر اختيار المؤثر المناسب وطلب خدمات
					الإعلان التي يقدمها من خلال المنصّات، وفي المقابل يقوم المؤثر بتلبية
					وتنفيذ طلبات التاجر وفقًا للاتفاق بين الطرفين.
				</p>

				<p>
					1.2. تُحدِّد شروط الاستخدام هذه وسياسة الخصوصيّة الإرشاداتِ اللازمةِ
					لطريقة استخدام منصّات <span className='font-bold'>"المشاهير"</span>
					والخدمات والاستفادة منها، ومن المهم قراءتها وفهمها جيدًا قبل استخدام
					خدمات المنصّات؛ إذا كنت لا توافق على هذه الشّروط، فيرجى عدم استخدام
					هذه المنصّات.
				</p>

				<p>
					1.3. تحتفظ{" "}
					<span className='font-bold'>
						مؤسسة منصّات المشاهير للتسويق الإلكتروني
					</span>{" "}
					بحقها في تغيير، أو تعديل، أو إضافة، أو إزالة كل/أو جزء من هذه الشّروط
					في أي وقت. ننصحُك وجميع مُستخدمي منصّات{" "}
					<span className='font-bold'>"المشاهير"</span> بمراجعة هذه الشّروط
					بانتظام للاطّلاع على أيّ تحديثات عليها، وستُصبح التحديثات سارية
					تلقائيًا عند نشرها على هذه الصفحة
				</p>
			</div>,
			<div className='box flex-col justify-start items-start'>
				<h6>[2] التعريفات</h6>

				<p>
					يكون للمصطلحات التالية المعنى الموضح بجانب كل منها ما لم يقتضِ السياق
					خلاف ذلك: 2.1. <span className='font-bold'>"المنصات"</span>: يُقصد بها
					منصّات
					<span className='font-bold'>"المشاهير"</span> والتي تقدّم خدماتها من
					خلال الموقع https://celebrity.sa وتطبيقات الجوّال التي يمكن تحميلها من
					متجر أندرويد أو متجر آبل.
				</p>

				<p>
					2.2. <span className='font-bold'>"نحن"</span> أو {"  "}
					<span className='font-bold'>"ضمير المتكلم"</span>
					أو <span className='font-bold'>"ضمير الملكية"</span>: يُقصد بها مؤسسة{" "}
					<span className='font-bold'>
						مؤسسة منصّات المشاهير للتسويق الإلكتروني.
					</span>{" "}
				</p>

				<p>
					2.3. <span className='font-bold'>"أنت"</span> أو
					<span className='font-bold'>"ضمير الملكية للمخاطب"</span>: يُقصد به كل
					من يستخدِم منصّات <span className='font-bold'>"المشاهير"</span> بأي
					شكلٍ من الأشكال سواء كان مؤثر أو تاجر أو مُستخدِم نهائي، أو يصل إلى
					المنصّات و/أو يستفيد من الخدمات المتاحة من خلالها أو يحصل على أي من
					المعلومات المنشورة فيها سواء كان بشكل مباشر أو غير مباشر.
				</p>

				<p>
					2.4. <span className='font-bold'>"المؤثر"</span>: يُقصد به كل شخص
					مشهور أو ذو تأثير على منصّات التواصل الاجتماعي ولديه عدد كبير من
					المتابعين، ويسجل حساب في منصّات "المشاهير" ويقدم خدمات إعلانيّة
					وتسويقيّة للتاجر وفقًا لشروط وأحكام المنصّات.
				</p>

				<p>
					2.5. <span className='font-bold'>"التاجر"</span>: يُقصد به كل شخص
					طبيعي أو معنوي يطلب خدمات إعلانيّة من المؤثرين عن طريق منصّات
					"المشاهير".
				</p>

				<p>
					2.6. <span className='font-bold'>"المُستخدِم النهائي"</span>: يُقصد به
					كل من يسجل حساب في منصّات "المشاهير" ويوثّق حسابه بغرض طلب الخدمات
					التي يقدمها المؤثرين.
				</p>

				<p>
					2.7. <span className='font-bold'>"الخدمات"</span>: يُقصد بها خدمات طلب
					الإعلان أو الترويج للمنتجات أو الخدمات التي يقدمها المؤثرين لصالح
					التجار، أو أي عمل آخر يتم الاتفاق عليه من خلال منصّات{" "}
					<span className='font-bold'>"المشاهير"</span>.
				</p>

				<p>
					2.8. <span className='font-bold'>"المحتوى"</span>: يُقصد به مُحتوى
					منصّات <span className='font-bold'>"المشاهير"</span> ويشمل ذلك
					المعلومات، والبيانات، والمواد، والنصوص، والصور، ومقاطع الفيديو،
					والصوت، والرسومات، والميزات التفاعليّة التي يتم توفيرها أو إتاحتها
					للمُستخدِم.
				</p>

				<p>
					2.9. <span className='font-bold'>"الملكية الفكرية"</span>: يُقصد بها
					كافة الحقوق المتعلقة بمنصّات{" "}
					<span className='font-bold'>"المشاهير"</span>، والتي تشمل على سبيل
					المثال لا الحصر الحقوق والأعمال البرمجيّة، حقوق المؤلّف، حقوق الطبع
					والنشر، براءة الاختراع، العلامات التّجارية -سواء مسجّلة أو غير
					مسجّلة-، اسم النطاق (بأيّ امتداد)، الشعارات، التصاميم، الرسومات، الاسم
					التّجاريّ، الوسم التّجاريّ، الرخصة التّجاريّة، وغيرها من الحقوق
					المقررة أو المرتبطة بالمنصّات سواء كان مسجّلة أو غير مسجّلة.
				</p>

				<p>
					2.10. <span className='font-bold'>"القوة القاهرة"</span>: يُقصد بها
					أيّ حدث سواء كان مباشر أو غير مباشر أو ظرف خارج عن سيطرة الطرف الذي
					تأثر به، ويمنعه من تنفيذ أيّ أو جميع التزاماته التي تتجاوز السيطرة
					المعقولة.
				</p>

				<p>
					2.11. <span className='font-bold'>"الأطراف الثالثة"</span>: يُقصد بها
					كافة الأشخاص والجهات الأخرى غير التابعة للمنصّات والتي قد تشاركنا في
					تقديم الخدمات أو التي قد توفّر خدماتها من خلال منصّات{" "}
					<span className='font-bold'>"المشاهير"</span>، أو التي قد تساهم في نشر
					أو استخدام خدماتنا.
				</p>

				<p>
					2.12. "القوانين": يُقصد بها الأنظمة، واللوائح، والأوامر، والمراسيم
					السارية في المملكة العربيّة السعوديّة.
				</p>

				<p>
					2.13. <span className='font-bold'>"الشّروط والأحكام"</span>: يُقصد بها
					شّروط الاستخدام هذه وسياسة الخصوصيّة وكافة الصفحات ووصف الخدمات وكافة
					التعليمات والقواعد الخاصة بتشغيل منصّات{" "}
					<span className='font-bold'>"المشاهير"</span>.
				</p>
			</div>,
			<div className='box flex-col justify-start items-start'>
				<h6> [3] العقد الإلكتروني</h6>

				<p>
					3.1. تعد هذه الشّروط عقدًا إلكترونيًا صحيحًا مكتملاً الأركان القانونية
					بينك (المؤثر / التاجر / المُستخدِم النهائي) وبين{" "}
					<span className='font-bold'>
						"مؤسسة منصّات المشاهير للتسويق الإلكتروني"
					</span>{" "}
					فيما يتعلق باستخدام منصّات{" "}
					<span className='font-bold'>"المشاهير"</span>، وتكون كافة الآثار
					القانونية الملزمة لهذا العقد سارية المفعول من تاريخ إنشاء حساب، وتصبح
					ملزمًا بها كالتزامك بالعقود المبرمة كتابةً، وينتج عنها نفس الآثار
					القانونية التي تنتجها هذه العقود. 3.2. يكون للتعديلات الواردة على هذه
					الشّروط نفس الأحكام والآثار القانونية للنُسخة الحالية من هذه الشّروط.
				</p>
			</div>,

			<div className='box flex-col justify-start items-start'>
				<h6>[4] نطاق خدمات المنصّات </h6>

				<p>
					4.1. <span className='font-bold'>"المشاهير"</span> هي منصّات
					إلكترونيّة تعمل كوسيط عبر الإنترنت تربط بين المؤثرين والمشاهير
					المسجّلين في المنصّات لعرض خدماتهم الإعلانيّة والترويج لها، وبين
					التجّار الذين يطلبون خدمات الإعلان عن منتجاتهم أو خدماتهم من المؤثرين
					حسب شروط الاتفاق بين الطرفين.
				</p>

				<p>
					4.2. توفّر منصّات <span className='font-bold'>"المشاهير"</span> ما
					يلي:
					<div className='sub-list'>
						<p>4.2.1. تسجيل حساب والاستفادة من ميزات المنصّات.</p>
						<p>
							4.2.2. البنية التقنيّة لتشغيل المنصّات بشكل آمن، ومركز الاتصال
							والدعم الفني.
						</p>

						<p>
							4.2.3. لوحة تحكم للمؤثر لعرض خدماته على المنصّات، وإدارة الطلبات.
						</p>
						<p>4.2.4. وسائل الدفع المختلفة التي تتناسب مع الجميع.</p>
					</div>
				</p>
				<p>
					4.3. قد توفّر المنصّات خدمات أخرى للمؤثر مثل خدمات الدعم التسويقي، أو
					خدمات الإهداء للمناسبات الاجتماعية.
				</p>

				<p>
					4.4. أنت تعلم وتوافق بأن منصّات{" "}
					<span className='font-bold'>"المشاهير"</span> وسيط تقني على شبكة
					الانترنت تتيح للمؤثر إنشاء ملف تعريفي من خلال لوحة التحكم في حسابه
					والإعلان عن نفسه وعن الخدمات التي يقدمها لاستقطاب طلبات إعلانيّة،
					وتساعد التاجر على اختيار المؤثر المناسب وطلب خدماته الإعلانيّة من خلال
					المنصّات.
				</p>

				<p>
					4.5. قد تخضع بعض خدمات المنصّات لعقود أو شروط إضافية، وتكون مكمّلة
					لهذه الشّروط وجزءً لا يتجزأ منها
				</p>

				<p>
					4.6. تقدّم جميع خدمات منصّات{" "}
					<span className='font-bold'>"المشاهير"</span> بما يتوافق مع القوانين
					والأنظمة السارية في المملكة العربيّة السعوديّة، وتطبق عليها أيضًا كافة
					القوانين ذات الصلة والمرتبطة بتشغيل المنصّات، أو استخدام الخدمات.
				</p>

				<p>
					4.7. تحتفظ منصّات <span className='font-bold'>"المشاهير"</span> -حسب
					تقديرها الخاص- بالحق في تحديد المُحتوى، والمظهر، والتصميم، والأداء
					الوظيفي، وجميع الجوانب الأخرى المُتعلّقة بالموقع الإلكتروني وتطبيقات
					الجوّال.
				</p>
			</div>,
			<div className='box flex-col justify-start items-start'>
				<h6>[5] الحساب</h6>

				<p>
					5.1. للاستفادة من خدمات منصّات{" "}
					<span className='font-bold'>"المشاهير"</span>، يجب عليك تسجيل حساب
					وفقًا لنموذج التسجيل المحدد في المنصّات، وستكون مطالبًا بتزويدنا
					ببيانات صحيحة ودقيقة وحديثة وكاملة، ولن يُقبل تسجيلُك ما لم تزودنا
					بهذه البيانات.
				</p>

				<p>
					5.2. يجب توافر الشروط التالية عند تسجيل حساب في منصّات{" "}
					<span className='font-bold'>"المشاهير"</span>:
					<div className='sub-list'>
						<p>
							5.2.1. أن يكون التسجيل بالاسم الحقيقي، وفي حالة التسجيل نيابة عن
							كيان تجاري (شركة أو مؤسسة)، فأنت تقرُ بأنّ لديك سلطة إلزام هذا
							الكيان بهذه الشّروط وجميع الأنظمة السارية في المملكة العربيّة
							السعوديّة.
						</p>
						<p>
							5.2.2. تقديم كافة الأوراق والمستندات الرسمية التي تثبت ممارسة
							النشاط وبيانات الهوية عند طلبها، والالتزام بتحديث هذه البيانات عند
							تغييرها والمستندات في حال انتهاء سريانها.
						</p>

						<p>
							5.2.3. تحري الدقة عند إدخال البيانات على منصّات{" "}
							<span className='font-bold'>"المشاهير"</span>، ومراجعة تلك
							البيانات بشكل منتظم بغرض تصحيحها أو تعديلها أو تجديدها كلما طرأ
							عليها أي تغيير أو تحديث.
						</p>
						<p>
							5.2.4. الحفاظ على أمان وسرية بيانات الدخول للحساب (اسم المُستخدِم
							وكلمة المرور) طوال الوقت، وتحمُل مسؤوليّة أي استخدام يقع من أي شخص
							قمت بالإفصاح له عن هذه البيانات.
						</p>

						<p>
							5.2.5. تحمل مسؤولية إدارة الحساب، وجميع الأنشطة التي تحدث من خلال
							الحساب.
						</p>

						<p>
							5.2.6. إبلاغ إدارة منصّات{" "}
							<span className='font-bold'>"المشاهير"</span> على الفور بأي
							استخدام غير قانونيّ للحساب أو أي اشتباه في استخدامه، وذلك لاتخاذ
							الإجراءات التقنيّة اللازمة للمحافظة على الحساب.
						</p>
						<p>
							5.2.7. تقييد الغير من استخدام الحساب، وتحمُل المسؤوليّة عن الأنشطة
							التي تحدث من خلاله.
						</p>
						<p>
							5.2.8. استخدام الحساب بما يتوافق مع القوانين السارية في المملكة
							العربية السعودية، والامتثال لهذه الشّروط.
						</p>
						<p>
							5.2.9. عدم الشروع بأي تصرف من شأنه إلحاق أي ضرر -مباشر أو غير
							مباشر- بنشاط المنصّات أو سمعتها أو أهدافها أو توجهاتها سواء بشكلٍ
							مباشر أو غير مباشر.
						</p>
					</div>
				</p>

				<p>
					5.3. يمكنك تحديث بياناتك الشخصيّة في الملف الشخصي وإكمالها، أو
					تعديلها، أو تصحيحها متى شاء من خلال الوصول إلى تبويب "حسابي"، وقد لا
					تتوفر إمكانية التعديل على بعض خانات البيانات.
				</p>
				<p>
					5.4. يجب أن يكون المحتوى الذي تقدمه من حسابك قانوني، ولا يتضمن أي
					انتهاك لحقوق الآخرين.
				</p>
				<p>
					5.5. يحق لنا التحقق من بيانات ومتطلبات التسجيل والمعلومات التي تقدمها
					لنا، وبموجب هذه الشّروط أنت تفوضنا بطلب واستخدام وتخزين تلك المعلومات
					وفقًا لسياسة الخصوصيّة في المنصّات.
				</p>
				<p>
					5.6. يحق لإدارة منصّات <span className='font-bold'>"المشاهير"</span>{" "}
					إيقاف أو إلغاء حسابك أو تعليق وصولك لخدمات المنصّات في حالة عدم
					الالتزام بهذه الشّروط، كما نحتفظ بالحق في إلغاء أي حسابات غير مؤكّدة
					أو حسابات مر عليها مدة طويلة دون نشاط.
				</p>
				<p>
					5.7. لا يحق لك استخدام منصّات{" "}
					<span className='font-bold'>"المشاهير"</span> إذا أُلغي حسابك من قبل
					المنصّات أو بموجب أوامر أو أحكام قضائية.
				</p>
			</div>,
			<div className='box flex-col justify-start items-start'>
				<h6>[6] الأمان</h6>
				<p>
					6.1. تعتمد منصّات <span className='font-bold'>"المشاهير"</span> على
					معايير أمنية (مثل الاستعانة بشركات الاستضافة، وشركات تأمين الدفع
					الإلكتروني) لمواجهة أعمال القرصنة الإلكترونية، واختراق قواعد البيانات؛
					وأنت توافق صراحة على المعايير الأمنية التي نتبعها من أجل تأمين
					المنصّات والحسابات المسجلة لدينا.
				</p>
				<p>
					6.2. أنت توافق على اتّباع التعليمات الصادرة من منصّات{" "}
					<span className='font-bold'>"المشاهير"</span> بشأن حسابه، بما في ذلك
					طريقة استخدام الحساب، وكافة محظورات الاستخدام.
				</p>
				<p>
					6.3. أنت توافق على اتخاذ كافة إجراءات العناية المعقولة للتأكد من أمان
					البريد الإلكتروني المرتبط بحسابه، وعدم استخدام الحساب من قِبل أيّ شخص
					آخر، حيث يُستخدم البريد الإلكتروني لإعادة تعيين كلمة مرور الحساب، وقد
					نستخدمه للتواصل معك فيما يتعلق بأمان الحساب.
				</p>
			</div>,

			<div className='box flex-col justify-start items-start'>
				<h6>[7] رخصة الاستخدام</h6>

				<p>
					7.1. تمنحك{" "}
					<span className='font-bold'>
						مؤسسة منصّات المشاهير للتسويق الإلكتروني
					</span>{" "}
					بموجب هذه الشّروط ترخيصًا شخصيًا محدودًا وغير حصري وغير قابل للتحويل
					وغير قابل للمُخالفة وقابل للإلغاء من أجل الوصول إلى الموقع الإلكتروني
					https://celebrity.sa ومحتواه والمواد المنشورة عليه وتنزيل تطبيقات{" "}
					<span className='font-bold'>"المشاهير"</span> وتثبيتها على جهازك،
					وفقًا لبنود هذه الشّروط؛ فلا يجوز:
				</p>
				<p>
					<div className='sub-list'>
						<p>7.1.1. نقل الخدمة أو ترخيصها من الباطن إلى أي شخص آخر.</p>
						<p>
							7.1.2. إعادة إنتاج أو عمل نسخة مطابقة من الموقع الإلكتروني او
							تطبيقات الجوّال، أو نسخها، أو تعديلها.
						</p>
						<p>
							7.1.3. الهندسة العكسية أو إلغاء ترجمة أو إنشاء أعمال مشتقة من
							الموقع أو تطبيقات الجوّال.
						</p>
						<p>7.1.4. استخدام أكثر من حساب واحد لكل فرد.</p>
						<p>
							7.1.5. استخدام المنصّات بأي طريقة غير قانونية أو بطريقة لا تتماشى
							مع هذه الشّروط.
						</p>
					</div>
				</p>
				<p>
					7.2. لا يشمل الترخيص الممنوح لك أي حقوق ملكية في منصّات{" "}
					<span className='font-bold'>"المشاهير"</span> أو جزء منها، أو أي خدمة
					مقدمة من خلالها، كما لا يشير هذا الترخيص بشكل مباشر أو غير مباشر لوجود
					شراكة من أي نوع بيننا وبينك أو أي مُستخدِم.
				</p>
			</div>,

			<div className='box flex-col justify-start items-start'>
				<h6>[8] سلوك الاستخدام</h6>

				<p>
					8.1. أنت توافق على استخدام منصّات{" "}
					<span className='font-bold'>"المشاهير"</span> بما يتوافق مع هذه
					الشّروط وبطريقة تتوافق كحد أدنى مع المعايير السائدة للإنترنت، على سبيل
					المثال ودون حصر، فلا يجوز لك القيام بأي مما يلي:
				</p>
				<p>
					<div className='sub-list'>
						<p>
							8.1.1. استخدام المنصّات لانتهاك أي قانون أو لائحة معمول بها في
							المملكة العربية السعودية.
						</p>
						<p>
							8.1.2. استخدام المنصّات لانتهاك حقوقنا أو حقوق أي طرف ثالث، بما في
							ذلك على سبيل المثال لا الحصر الملكية الفكرية، والخصوصية، وحقوق
							النشر، والعلامة التجارية، وبراءات الاختراع، وقواعد البيانات،
							والحقوق التعاقدية، أو أي من حقوق الملكية الفكرية التي تتعلق بنا أو
							بالغير.
						</p>
						<p>
							8.1.3. استخدام المعلومات التي تم الحصول عليها من خلال المنصّات لأي
							غرض غير مصرح به.
						</p>
						<p>
							8.1.4. التدخل في طبيعة عمل المنصّات عن طريق نشر الفيروسات، أو
							البرمجيات الخبيثة، أو برامج التجسس، أو أي تقنيات أخرى مثل برامج
							الإلغاء الآلية، أو التعليمات البرمجية الضارة، أو الأساليب، أو
							التقنيات المماثلة التي قد تعطل أو تتدخل في تشغيل أو توفير
							المنصّات، أو التي تهدف إلى إتلاف أي نظام أو بيانات أو معلومات
							شخصيّة أو تعطيلها أو مصادرتها.
						</p>
						<p>
							8.1.5. استخدام المنصّات بشكل غير قانوني لنقل أو توزيع أو نشر أو
							تقديم أي معلومات خاصة تتعلق بأي شخص أو كيان آخر، بما في ذلك على
							سبيل المثال لا الحصر، معلومات الاتصال الشخصية أو الحسابات أو غير
							ذلك.
						</p>
						<p>
							8.1.6. استخدام المنصّات فيما يتعلق بتوزيع البريد الإلكتروني أو
							الإعلانات التجارية غير المرغوب فيها.
						</p>
						<p>
							8.1.7. جمع أي معلومات أو بيانات عن أي مستخدم آخر بخلاف ما يحدث
							عادة في سياق الاستخدام المسموح به؛ أو انتحال شخصية أي شخص أو كيان.
						</p>
						<p>
							8.1.8. جمع أو تجميع المعلومات من المنصّات باستخدام أي وسائل يدوية
							أو آلية.
						</p>
						<p>
							8.1.9. إزالة أي حقوق نشر أو علامات تجارية أو غيرها من إشعارات حقوق
							الملكية أو العناوين التفسيرية المضمنة في الخدمة.
						</p>
						<p>
							8.1.10. تحميل أو إدخال أو نقل أو بث أي مادة لا يحق لها نقلها بموجب
							أي قانون أو علاقة تعاقدية.
						</p>
						<p>
							8.1.11. تعديل، تغيير، الترخيص من الباطن، ترجمة، بيع، عكس هندسة، حل
							شفرة، فك أو فصل أي جزء من الموقع أو التطبيقات أو أي برنامج يستخدم
							في الخدمة أو من أجلها.
						</p>
						<p>
							8.1.12. مساعدة أو التسهيل على أي طرف ثالث في القيام بأي مما سبق.
							8.2. لا يجوز لك استخدام المنصّات لتطوير أو إنشاء أو نقل أو تخزين
							المعلومات التي: 8.2.1. تكون تشهيرية أو ضارة أو مسيئة أو فاحشة أو
							بغيضة.
						</p>
						<p>
							8.2.2. تعرقل أو تتعارض مع الأداء العادي لاستخدام شخص آخر للموقع
							و/أو التطبيقات بأي شكل كان.
						</p>
						<p>
							8.2.3. تقوم بأي اتصالات تجارية غير مطلوبة لا يسمح بها القانون
							المعمول به.
						</p>
						<p>
							8.2.4. تشكل تحرشًا أو انتهاكًا للخصوصية أو تهدد أشخاصًا أو مجموعات
							أخرى من الناس.
						</p>
						<p>
							8.2.5. تجعل أي بيان أو تمثيل زائف أو مضلل أو خادع يخصنا و/أو
							الموقع أو التطبيقات.
						</p>
						<p>
							تمثل الاحتيال أو التزييف أو انتحال شخصية أي شخص آخر، أو سرقة أو
							تحمل هوية أي شخص (سواء كان له هوية حقيقية أو لقب أو اسم مستعار على
							الإنترنت).
						</p>
					</div>
				</p>
				<p>
					8.3. أنت توافق على تحمُل كافة المسؤوليات القانونية في حالة انتهاكك أي
					من حقوق منصّات <span className='font-bold'>"المشاهير"</span>.
				</p>
			</div>,

			<div className='box flex-col justify-start items-start'>
				<h6>[9] محتوى الاستخدام</h6>

				<p>
					9.1. بإرسال أي محتوى إلى منصّات{" "}
					<span className='font-bold'>"المشاهير"</span>، فأنت تقر وتضمن لنا أن
					هذا المحتوى صادق وصحيح من جميع النواحي، وأن لديك الحق في إرسال هذا
					المحتوى، وأن استخدامنا للمحتوى الخاص بك كما هو موضّح في المنصّات لن
					يخرق أو ينتهك حقوق أي طرف ثالث، بما في ذلك على سبيل المثال لا الحصر أي
					حقوق ملكية فكرية وحقوق خصوصيّة.
				</p>
				<p>
					9.2. قد يتم تضمين هذه المواد في الخدمة سواء كليًا أو جزئيًا بناءً على
					تقديرنا الخاص. وفيما يتعلق بمثل هذه المواد التي تقدمها أو توفرها
					للتضمين على المنصّات، فأنت تمنحنا ترخيصًا دائمًا وغير قابل للإلغاء
					وغير قابل للفسخ وغير حصري لاستخدامه أو نسخه أو توزيعه أو عرضه بشكل عام
					أو تعديله أو إنشاء أعمال مشتقة وترخيص مثل هذه المواد أو أي جزء منها.
				</p>
				<p>
					9.3. يحق لنا -وفقًا لتقديرنا الخاص- إزالة أي محتوى للمُستخدِم يتم
					مشاركته عبر منصّات <span className='font-bold'>"المشاهير"</span>،
					وتكون وحدك مسؤولاً عن المحتوى الاستخدام الخاص بك وعواقب مشاركته أو
					نشره.
				</p>
			</div>,

			<div className='box flex-col justify-start items-start'>
				<h6>[10] المسؤولية القانونية</h6>

				<p>
					10.1. يتم توفير الموقع الإلكتروني https://celebrity.sa وتطبيقات
					الجوّال والخدمات والمحتوى والمعلومات المتوفرة عليها أو المرتبطة بها
					على أساس ثابت "كما هو" و "كما هو متوفر" دون أيّ ضمانات أو كفالات أو
					تعهدات من أيّ نوع سواء كانت صريحة أو ضمنيّة.
				</p>
				<p>
					10.2. لا تقدّم منصّات <span className='font-bold'>"المشاهير"</span> أي
					إقرارات أو تعهدات أو ضمانات فيما يتعلق بموثوقية خدمات المؤثرين، أو
					توقيتها، أو جودتها، أو ملاءمتها، أو خلوها من الأخطاء.
				</p>
				<p>
					10.3. لا تضمن منصّات <span className='font-bold'>"المشاهير"</span> أن
					المعلومات التي تقدّم من خلالها ستكون دقيقة ومحدّثة، ويجب عليك التحقق
					من المعلومات المتاحة بنفسك.
				</p>
				<p>
					10.4. أنت توافق على تحمل مسؤولية استخدامك لمنصّات{" "}
					<span className='font-bold'>"المشاهير"</span> وخدماتها، أو أي معلومات
					يرسلها.
				</p>
				<p>
					10.5. لا تتحمّل منصّات <span className='font-bold'>"المشاهير"</span>"
					المسؤوليّة بأي شكلٍ من الأشكال عن الخدمات التي يقدمها المؤثرين من
					خلالها، ويتحمّل كل مؤثر مسؤولية قانونيّة هذه الخدمات وكافة الأمور
					المتعلقة ببيعها أو تنفيذها أو الإخلال بالوقت المحدد لتنفيذها.
				</p>
				<p>
					10.6. لن نتحمّل المسؤولية عن أي أضرار تنتج عن استخدام (أو عدم القدرة
					على استخدام) منصّات <span className='font-bold'>"المشاهير"</span> بما
					في ذلك الأضرار التي تسببها البرامج الضارة أو الفيروسات، كما لا نتحمل
					مسؤولية عدم صحة أو عدم اكتمال المعلومات أو المنصّات، ما لم يكن هذا
					الضرر ناتج عن سوء سلوك عمدي أو عن إهمال جسيم من جانبنا.
				</p>
				<p>
					10.7. لن نتحمل المسؤوليّة في الحالات التالية:
					<div className='sub-list'>
						<p>
							10.7.1. العطل أو الانقطاع أو التوقُف أو الاضطراب أو عدم التوافر
							(المؤقت و/أو الجزئي) لمنصّات{" "}
							<span className='font-bold'>"المشاهير"</span>.
						</p>
						<p>
							10.7.2. الانقطاع في الخدمة بسبب أعمال الصيانة الدورية أو إصلاح
							المشكلات الفنية أو ما شابه.
						</p>
						<p>10.7.3. الروابط التي يقدمها المؤثر من خلال حسابه.</p>
						<p>
							10.7.4. روابط المواقع الإلكترونيّة المُرتبطة بمنصّات{" "}
							<span className='font-bold'>"المشاهير"</span>، ولا تُعَد هذه
							الروابط مُصادَقَةً منا عليها.
						</p>
						<p>
							10.7.5. التقصير أو التأخير في أداء أي من التزاماتنا إذا كان ذلك
							بسبب القوة القاهرة أو الظروف الطارئة.
						</p>
					</div>
				</p>
				<p>
					10.8. نسعى لبذل قصارى جهدنا لضمان توافر منصّات{" "}
					<span className='font-bold'>"المشاهير"</span> للاستخدام على مدار
					الساعة، ولكن قد تكون المنصّات غير متوفرة من وقت لآخر بسبب أعمال
					الصيانة الدورية أو إصلاح المشكلات الفنية أو التطوير أو ما شابه.
				</p>
				<p>
					10.9. أنت تقر بأن منصّات <span className='font-bold'>"المشاهير"</span>{" "}
					عبارة عن خدمة قائمة على الإنترنت، وأنه على الرغم من بذلنا أقصى جهد
					للحفاظ على المعلومات وإبقائها آمنة، إلا أننا لا نستطيع أن نضمن أن تكون
					المعلومات التي تستلمها أو ترسلها أثناء استخدامك للمنصّات آمنة في كل
					الأوقات.
				</p>
				<p>
					10.10. قد نقوم في أي وقت بتعديل أو وقف أو قطع خدمات منصّات{" "}
					<span className='font-bold'>"المشاهير"</span>
					في حالة انتهاكك لهذه الشّروط أو إذا أسات استخدام المنصّات من وجهة
					نظرنا.
				</p>
			</div>,

			<div className='box flex-col justify-start items-start'>
				<h6>[11] حقوق الملكيّة الفكريّة</h6>

				<p>
					11.1. جميع المحتويات والمواد المتوفرة على منصّات{" "}
					<span className='font-bold'>"المشاهير"</span> وحقوق النشر والعلامات
					التجارية وعلامات الخدمة وبراءات الاختراع والأسرار التجارية والأسماء
					وشعار المنصّات والتصاميم وغيرها من حقوق الملكية الفكرية بما فيها
					الحقوق البرمجية وغيرها من الرموز التي يحتوي عليها بما في ذلك المحتوى
					والنصوص والخطوط والصور والبرامج وملفات الصوت والموسيقى والفيديو
					والمواد الرقمية، والوثائق والبيانات والأشكال، هي علامات تجارية تابعة
					لـ{" "}
					<span className='font-bold'>
						"مؤسسة منصّات المشاهير للتسويق الإلكتروني"
					</span>
					أو الجهات المرخصة لها، وهي محمية بموجب قوانين الملكية الفكرية.
				</p>
				<p>
					11.2. جميع الأسماء الأخرى والشعارات وأسماء المنتجات والخدمات والتصاميم
					والشارات على منصّات <span className='font-bold'>"المشاهير"</span> هي
					علامات تجارية لمالكيها.
				</p>
				<p>
					11.3. لا يوجد في هذه الشّروط ما يُنشأ أو يُفسّر بأنّه يُنشأ أو يمنح
					أيّ مُستخدِم حق ترخيص أو استخدام العلامة التجاريّة لمنصّات{" "}
					<span className='font-bold'>"المشاهير"</span>
					بدون الحصول على إذن كتابي أو موافقة مسبقة منا.
				</p>
				<p>
					11.4. لا يجوز لك انتهاك أيّ حقوق ملكيّة فكريّة، أو علامات تجاريّة، أو
					برمجيات نستخدمها أو توفّرها لنا الأطراف الثالثة عند استخدام الموقع
					وتطبيقات الجوّال.
				</p>
				<p>
					11.5. لا يجوز لك انتهاك تصاميم أو محتوى الخدمة أو محاولة التعديل عليها
					أو استخدامها في أي أغراض أخرى.
				</p>
				<p>
					11.6. تحتفظ{" "}
					<span className='font-bold'>
						"مؤسسة منصّات المشاهير للتسويق الإلكتروني"
					</span>{" "}
					بجميع الحقوق في الموقع الإلكتروني وتطبيقات الجوّال وجميع الأجهزة
					والبرامج والعناصر الأخرى المستخدَمة لتوفير منصّات{" "}
					<span className='font-bold'>"المشاهير"</span>، بخلاف الحقوق الممنوحة
					لك صراحة لاستخدام المنصّات وفقًا لهذه الشّروط.
				</p>
			</div>,

			<div className='box flex-col justify-start items-start'>
				<h6>[12] الوفاة</h6>

				<p>
					في حالة وفاة المؤثر فإنه يحق لورثته طلب استلام أرباحه ومستحقاته في
					منصّات <span className='font-bold'>"المشاهير"</span> -إن وجدت- بعد
					إصدار صك حصر الورثة وتفويض من ينوب عنهم في استلامها.
				</p>
			</div>,

			<div className='box flex-col justify-start items-start'>
				<h6>[13] التعويض</h6>

				<p>
					أنت توافق على تعويض{" "}
					<span className='font-bold'>
						"مؤسسة منصّات المشاهير للتسويق الإلكتروني"
					</span>{" "}
					والدفاع عنها وحمايتها ومنسوبيها ومُلّاكها والتابعين لها من الأضرار
					الناتجة عن أي نزاعات أو شكاوى أو تصرفات أو إجراءات أو خسائر أو مسائلات
					أو المطالبات التي قد يرفعها أو يطالب بها الغير، أو الغرامات، أو
					التكاليف، أو النفقات بما في ذلك الرسوم القانونية وأتعاب المحاماة التي
					تنشأ عن انتهاكك لهذه الشّروط أو أي من القوانين الساريّة، أو الحقوق
					الخاصة بالطرف الثالث، أو بسبب إساءة استخدام المنصّات، أو نتيجة
					استخدامك غير القانوني لمنصّات{" "}
					<span className='font-bold'>"المشاهير"</span> مهما كان نوعه.
				</p>
			</div>,

			<div className='box flex-col justify-start items-start'>
				<h6>[14] روابط المواقع الخارجية</h6>

				<p>
					14.1. قد تحتوي منصّات <span className='font-bold'>"المشاهير"</span>{" "}
					على روابط لمواقع خارجية خاصة بأطراف أخرى، وليس لدينا أي سيطرة على تلك
					المواقع أو محتوياتها، ولا تتحمّل أي مسؤولية عنها أو عن أي خسارة أو ضرر
					قد ينشأ عن استخدامك لها، وبموجب هذا أنت توافق على عدم إشراكنا في أيّ
					نزاع قد يكون لديك مع أيّ موقع إلكتروني لأي طرف ثالث.
				</p>
				<p>
					14.2. يرجى العلم إنّك إن وصلت، أو أُعيد تحويلك إلى مواقع تملكها جهات
					خارجية أخرى عند استخدامك منصّات{" "}
					<span className='font-bold'>"المشاهير"</span>، فربما يكون لتلك المواقع
					الخارجية الأخرى شروط وسياسات تختلف عن المتّبعَة لدينا، نحن لا نؤيد هذه
					المواقع ولا نُقدِم أيّ تعهدات (أو ندعمها بأيّ شكل من الأشكال) أو أيّ
					محتوى أو مواد أو خدمات متاحة على هذه المواقع الخارجية.
				</p>
				<p>
					14.3. لا نضمن بأيّ شكل أن هذه المواقع الخارجية ستكون خالية من أيّ
					أخطاء أو غموض أو سهو أو أن تكون مناسبة لأيّ غرض من الأغراض. يرجى
					مراجعة سياسات الخصوصيّة لمواقع الأطراف الأخرى قبل استخدامها أو تقديم
					بيانات عليها.
				</p>
			</div>,

			<div className='box flex-col justify-start items-start'>
				<h6>[15] الإشعارات والاتصالات الإلكترونية</h6>

				<p>
					15.1. أنت توافق على أن أي إشعارات ترسلها منصّات{" "}
					<span className='font-bold'>"المشاهير"</span> سوف تُسلّم إليك بواسطة
					البريد الإلكتروني الذي زوّدتنا به خلال عملية التسجيل. وأن أي إشعارات
					ترغب في إرسالها إلى منصّات{" "}
					<span className='font-bold'>"المشاهير"</span>، تكون من خلال البريد
					الإلكتروني وسيتم الرد على الرسالة الإلكترونية.
				</p>
				<p>
					15.2. يحق لنا التواصل معك إلكترونيًا من خلال البريد الإلكتروني المسجّل
					لدينا لتزويدك بالإعلانات أو فيما يتعلق بأي تغييرات في هذه الشّروط
					وسياسة الخصوصيّة، أو إرسال الرسائل الإدارية والإشعارات الفنية وتنبيهات
					الأمان، أو إعلامك بمنتجاتنا الجديدة، أو غير ذلك.
				</p>
				<p>
					15.3. أنت توافق على أنّ كل الاتصالات الإلكترونية تستوفي كافة الشّروط
					وتفي بجميع المتطلبات القانونية كما لو كانت هذه الاتصالات مكتوبة.
				</p>
				<p>
					15.4. في حال قررت في أي وقت عدم استقبال أو استلام مثل هذه الاتصالات،
					يُمكنك تعطيل وإيقاف استلام مثل هذه الرسائل بالنقر على رابط إلغاء
					الاشتراك داخل الرسالة الإلكترونية -إذا توفر.
				</p>
			</div>,

			<div className='box flex-col justify-start items-start'>
				<h6>[16] السرية وحماية البيانات</h6>

				<p>
					16.1. تستخدم منصّات <span className='font-bold'>"المشاهير"</span>{" "}
					البيانات الشخصيّة للمُستخدِم بالطريقة الموضّحة في سياسة الخصوصيّة
					والتي تعتبر جزءًا لا يتجزأ من الشّروط والأحكام، وعلى النحو المبين
					بمزيد من التفصيل في تلك السياسة.
				</p>
				<p>
					16.2. بخلاف ما هو مسموح بالنشر أو الإرسال، فإن منصّات{" "}
					<span className='font-bold'>"المشاهير"</span> تحافظ على سرية جميع
					المعلومات الشخصيّة التي يقدمها الأعضاء، وتتخذ جميع الخطوات الممكنة
					لحمايتها والحفاظ على سريتها؛ ومع ذلك، فإنه يجوز لمنصّات{" "}
					<span className='font-bold'>"المشاهير"</span>
					الإفصاح عن هذه المعلومات إذا كان ذلك مطلوبًا بموجب القانون.
				</p>
				<p>
					16.3. عند استخدام خدمات منصّات
					<span className='font-bold'>"المشاهير"</span>، فأنت تُقر وتوافق على
					جمع واستخدام بياناتك الشخصيّة بالطريقة المبينة في سياسة الخصوصيّة أو
					التعديلات التي تطرأ عليها بما يتناسب مع القوانين السّارية.
				</p>
			</div>,

			<div className='box flex-col justify-start items-start'>
				<h6>[17] المدة وإنهاء الوصول للخدمة</h6>

				<p>
					17.1. تدخل هذه الشّروط حيز التنفيذ من تاريخ تسجيل حساب والإقرار بقبولك
					لها وستظل سارية المفعول حتى يتم إنهاؤها من قبلك أو من قبلنا.
				</p>
				<p>
					17.2. أنت توافق على أنه يحق لنا -ووفقًا لتقديرنا الخاص- قد نقوم بإنهاء
					وصولك إلى خدماتنا لأي سبب أو بدون سبب، بما في ذلك على سبيل المثال لا
					الحصر انتهاك هذه الشّروط أو أي من سياساتنا، أو في حال تسببت استخداماتك
					لمنصّات <span className='font-bold'>"المشاهير"</span> بأيّ مشكلات
					قانونية، أو في حال ارتكاب أي تصرف غير قانوني أو يضر بالآخرين.
				</p>
				<p>
					17.3. أنت توافق على أن أي إنهاء لدخولك إلى منصّات
					<span className='font-bold'>"المشاهير"</span> قد يتم دون إشعار مسبق،
					وأنت تقر وتوافق على أنه يجوز لنا على الفور إلغاء أو حذف أي حسابك وجميع
					المعلومات والملفات ذات الصلة في هذه الحساب، ومنعك من الوصول إلى هذه
					الملفات أو المنصّات.
				</p>
				<p>
					17.4. أنت توافق على أننا لن نكون مسؤولين تجاهك أو تجاه أي طرف ثالث عن
					أي خسارة أو أضرار من أي نوع ناتجة عن إنهاء وصولك إلى خدماتنا.
				</p>
			</div>,

			<div className='box flex-col justify-start items-start'>
				<h6>[18] وقف الخدمة</h6>

				<p>
					نحتفظ بالحق في أي وقت بتعديل أو إيقاف -بشكل مؤقت أو دائم- أي جزء من
					منصّات <span className='font-bold'>"المشاهير"</span> . ويحق لنا وفقاً
					لتقديرنا الخاص:
					<div>
						{" "}
						<p>
							18.1. إزالة أو رفض نشر أي محتوى للمُستخدِم لأي سبب أو بدون سبب.
						</p>
						<p>18.2. اتخاذ الإجراءات القانونية المناسبة.</p>
						<p>18.3. إنهاء أو تعليق وصولك إلى كل أو جزء من المنصّات.</p>
						<p>
							18.4. التعاون الكامل مع أي سلطات لإنفاذ القانون أو أمر قضائي يطلب
							منا أو يوجهنا إلى الكشف عن هوية أو معلومات أخرى لأي شخص ينشر أي
							مواد على المنصّات أو من خلالها.
						</p>
					</div>
				</p>
			</div>,

			<div className='box flex-col justify-start items-start'>
				<h6>[19] التعديلات</h6>

				<p>
					19.1. يحق لـ{" "}
					<span className='font-bold'>
						مؤسسة منصّات المشاهير للتسويق الإلكتروني
					</span>
					، وفقاً لتقديرها المطلق، مراجعة وتعديل هذه الشّروط من وقت لآخر، وستكون
					هذه التعديلات سارية المفعول فور نشرها على هذه الصفحة، وسنقوم بتحديث
					تاريخ السريان.
				</p>
				<p>
					19.2. يحق لـ{" "}
					<span className='font-bold'>
						مؤسسة منصّات المشاهير للتسويق الإلكتروني
					</span>{" "}
					إجراء أية تعديلات أو تحسينات تراها ضرورية على منصّات{" "}
					<span className='font-bold'>"المشاهير"</span> لزيادة فاعليتها، ويجب
					عليك الالتزام بأية توجيهات أو تعليمات تقدّم إليك في هذا الشأن.
				</p>
				<p>
					19.3. أنت توافق على مراجعة هذه الشّروط لمعرفة أية تعديلات تتم عليها،
					ويعني استمرارك في استخدام منصّات
					<span className='font-bold'>"المشاهير"</span> بعد تحديث هذه الشّروط
					موافقة صريحة منك على هذه التعديلات وقبولاً قانونيًا بالنسخة الأحدث
					منها، وأنّ النسخة الحالية حلت محل جميع النسخ السابقة.
				</p>
			</div>,

			<div className='box flex-col justify-start items-start'>
				<h6>[20] القانون الواجب التطبيق</h6>

				<p>
					20.1. تحكم هذه الشّروط القوانين المعمول بها في المملكة العربيّة
					السعودية وتفسر وفقًا لها؛ وستخضع جميع النزاعات المتعلقة بها إلى الجهات
					القضائية المختصّة.
				</p>
				<p>
					20.2. تختص المحاكم السعوديّة بالنظر في أيّ نزاع قد ينشأ عن تفسير أو
					تنفيذ أحكام هذه الشّروط.
				</p>
			</div>,

			<div className='box flex-col justify-start items-start'>
				<h6>[21] قابلية الفصل</h6>

				<p>
					في حال أصبح أي حكم من أحكام هذه الشّروط غير ساري أو غير قانوني أو غير
					قابل للتنفيذ، فإن قانونية وقابلية تنفيذ الأحكام الأخرى لن تتأثر بأي
					طريقة كانت بذلك الحكم.
				</p>
			</div>,

			<div className='box flex-col justify-start items-start'>
				<h6>[22] تحويل الحقوق والالتزامات</h6>

				<p>
					22.1. يحق لـ{" "}
					<span className='font-bold'>
						مؤسسة منصّات المشاهير للتسويق الإلكتروني
					</span>{" "}
					تحويل كافة حقوقها و/أو التزاماتها الواردة في هذه الشّروط ونقلها
					والتعاقد عليها من الباطن إلى أي طرف ثالث دون أيّ اعتراض منك بشرط أن
					يوافق هذا الطرف الثالث على الالتزام بهذه الشّروط.
				</p>
				<p>
					22.2. لا يجوز للمُستخدِم أن يتنازل عن أي من حقوقه و/أو التزاماته
					الواردة في هذه الشّروط أو نقلها أو التعاقد عليها من الباطن دون
					موافقتنا الكتابية على ذلك.
				</p>
			</div>,

			<div className='box flex-col justify-start items-start'>
				<h6>[23] العلاقة القانونية</h6>

				<p>
					لن تفسّر بنود هذه الشّروط بوجود علاقة شراكة، أو توظيف، أو وكالة، أو
					مشروع مشترك بين منصّات <span className='font-bold'>"المشاهير"</span>{" "}
					الصادرة عن{" "}
					<span className='font-bold'>
						مؤسسة منصّات المشاهير للتسويق الإلكتروني
					</span>{" "}
					وبين أيّ مُستخدِم (سواء كان مؤثر أو تاجر)، وإنما هي علاقة تقديم خدمة
					وساطة إلكترونية وربط الأطراف من خلال المنصّات لأغراض إتمام وتنفيذ
					الطلبات.
				</p>
			</div>,

			<div className='box flex-col justify-start items-start'>
				<h6>[24] التعارض بين الشّروط</h6>

				<p>
					في حال تعارضت النسخة الحالية من هذه الشّروط مع أيّ إصدار سابق لها،
					فإنّ النسخة الحالية تكون هي المعتمدة.
				</p>
			</div>,

			<div className='box flex-col justify-start items-start'>
				<h6>[25] لغة الشّروط</h6>

				<p>
					نُشِرَت هذه الشّروط باللغةِ العربيّة، وفي حال ترجمتُها إلى لغةٍ
					أجنبيّةٍ أخرى، فإن النص العربيّ سيكون هو المعتمد، وعليه فلا يتم
					الاستناد إلى الترجمة المتوفرة في تفسير أي خلاف حول ما تتضمنه هذه
					الشّروط.
				</p>
			</div>,

			<div className='box flex-col justify-start items-start'>
				<h6>[26] الاتفاق الكامل</h6>

				<p>
					تُمثِّل هذه الشّروط بالإضافة إلى سياسة الخصوصيّة (أو تحديثاتها من حين
					لآخر) الاتفاقُ الكامل بينك وبين{" "}
					<span className='font-bold'>
						مؤسسة منصّات المشاهير للتسويق الإلكتروني
					</span>
					{"  "}
					فيما يتعلق باستخدام منصّات{" "}
					<span className='font-bold'>"المشاهير"</span>، وتحلُ محل أيّ إصدارات
					سابقة منها.
				</p>
			</div>,

			<div className='box flex-col justify-start items-start'>
				<h6>[27] التواصل معنا</h6>

				<p>
					أنت تقر بقراءة هذه الشّروط وتوافق على الالتزام ببنودها. إذا كانت لديك
					أيّ أسئلة أو استفسارات حول هذه الشّروط، فلا تتردد في التواصل معنا عبر
					البريد الإلكتروني info@celebrity.sa. جميع الحقوق محفوظة لـ{" "}
					<span className='font-bold'>
						مؤسسة منصّات المشاهير للتسويق الإلكتروني
					</span>{" "}
					| منصّات <span className='font-bold'>"المشاهير"</span> © 2024.
				</p>
			</div>,
		];
	}, [navigate]);

	// handle show more...
	const handleGetData = useCallback(() => {
		if (dataShow.length !== dataTarget().length) {
			let data = dataTarget().slice(0, sliceNumber);
			setDataShow(data);
			setSliceNumber((prev) => prev + 1);
		}
	}, [dataTarget, sliceNumber, dataShow.length]);
	const getDataRef = useRef(true);

	useEffect(() => {
		if (getDataRef.current) {
			handleGetData();
			getDataRef.current = false;
		}
	}, [handleGetData]);
	return (
		<>
			<div className='term-box min-h-[100vh]'>
				<TitleBox
					title='شروط الاستخدام'
					subTitle='مرحبًا بكم في منصّات "المشاهير"'
				/>
				<div className='container h-full flex justify-center items-center mt-[48px]'>
					<div className='content min-h-[681px] w-full py-[43px] px-[15px] md:px-[58px] flex gap-[43px] flex-col justify-start items-start'>
						{dataShow.length
							? dataShow.map((el) => {
									return el;
							  })
							: null}
						{dataShow.length !== dataTarget().length ? (
							<button
								className=' ml-auto py-[10px] px-[20px] bg-color-main-02 text-white text-[17px]'
								onClick={handleGetData}>
								{" "}
								عرض المزيد...
							</button>
						) : null}
					</div>
				</div>
			</div>
		</>
	);
};

export default TermsBox;
