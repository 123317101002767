import React, { useEffect, useState } from "react";

import { useScrollYPosition } from "react-use-scroll-position";

//css styles
import "./Footer.css";

// icons
import { KeyboardDoubleArrowUp } from "@mui/icons-material";
import {
	EarthWhite,
	Faceboock,
	Instagram,
	MessageWhite,
	PhoneWhite,
	Tiktok,
	Twitter,
} from "../../assets/Icon";

const Footer = () => {
	const [showup, setShowup] = useState(false);
	let scrolly = useScrollYPosition();

	useEffect(() => {
		if (scrolly >= 500) {
			setShowup(true);
		} else {
			setShowup(false);
		}
	}, [scrolly]);

	return (
		<>
			<footer className='min-h-[182px] bg-color-main-04 '>
				<div className='container flex  min-h-[182px] py-[60px] justify-center items-center lg:flex-row flex-col-reverse lg:gap-0 gap-[30px] '>
					<div className=''>
						<a
							href='mailto:info@celebrity.sa'
							target='blank'
							className='text-color-main-03'>
							info@celebrity.sa
							<span>
								<MessageWhite />
							</span>
						</a>
						<div className='mt-[30px] flex justify-center items-center gap-[15px]'>
							<a
								href='https://twitter.com/celebrityads1'
								className='w-[36px]  h-[36px] flex justify-center items-center '
								target='blank'>
								<img src={Twitter} alt='تويتر' />
							</a>
							<a
								href='https://www.tiktok.com/@celebrityads1'
								className='w-[36px]  h-[36px] flex justify-center items-center '
								target='blank'>
								<img src={Tiktok} alt='تيك توك' />
							</a>
							<a
								href='https://www.facebook.com/celebrityads1'
								className='w-[36px]  h-[36px] flex justify-center items-center '
								target='blank'>
								<img src={Faceboock} alt='فيسبوك' />
							</a>

							<a
								href='https://www.instagram.com/celebrityads1/'
								className='w-[36px]  h-[36px] flex justify-center items-center '
								target='blank'>
								<img src={Instagram} alt='انستجرام' />
							</a>
						</div>
						{/* <a
							href='https://blog.celebrityads.net'
							target='blank'
							className='text-color-main-03'>
							celebrity.sa
							<span>
								<EarthWhite />
							</span>
						</a> */}
					</div>
				</div>
				<div
					className={showup === true ? "go-up show " : "go-up"}
					onClick={() => {
						window.scroll(0, 0);
					}}>
					<span>
						<KeyboardDoubleArrowUp />
					</span>
				</div>
			</footer>
		</>
	);
};

export default Footer;
