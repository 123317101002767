import React, { useCallback, useRef } from "react";
import { NavLink } from "react-router-dom";
import { HighlightOff, Menu } from "@mui/icons-material";

import { Logo } from "../../assets/Img";

//css styles
import "./Header.css";

const Header = () => {
	let menuLink = useRef(null);
	const handleShowMenu = useCallback(() => {
		if (!menuLink.current.classList.contains("active")) {
			menuLink.current.classList.add("active");
		}
	}, []);
	const handleRemoveMenu = useCallback(() => {
		if (menuLink.current.classList.contains("active")) {
			menuLink.current.classList.remove("active");
		}
	}, []);
	return (
		<div className=' header-box  min-h-[120px] bg-color-main-06 justify-center flex items-center relative'>
			<div className='container'>
				<div className='header w-full  flex items-center justify-between gap-[20px] flex-row '>
					<div className='logo w-[200px] md:w-[278px] md:max-h-[56px]'>
						<img src={Logo} alt='لوجو منصات المشاهير' />
					</div>
					<div
						className='!text-main_color_02 cursor-pointer  flex justify-center items-center  min-[910px]:hidden'
						onClick={handleShowMenu}>
						<Menu className='!text-[32px] !font-[700]' />
					</div>
					<div className='link flex justify-center items-center max-[910px]:hidden '>
						<ul className='flex justify-center items-center gap-[24px] flex-wrap '>
							<NavLink to={"/"} className='text-[17px] font-[600] w-[67px]  '>
								الرئيسية
							</NavLink>

							{/*<NavLink
								to={"https://blog.celebrity.sa/"}
								className='text-[17px] font-[600] w-[60px]  '>
								المدونة
							</NavLink>*/}

							<NavLink
								to={"/about"}
								className='text-[17px] font-[600] w-[60px]  '>
								من نحن
							</NavLink>

							<NavLink
								to={"/term"}
								className='text-[17px] font-[600] w-[133px]  '>
								شروط الاستخدام
							</NavLink>
							<NavLink
								to={"/privacy"}
								className='text-[17px] font-[600]  w-[145px]  '>
								سياسة الخصوصية
							</NavLink>

							<NavLink
								to={"/contact"}
								className='text-[17px] font-[600]  w-[64px]  '>
								اتصل بنا
							</NavLink>
						</ul>
					</div>
				</div>
			</div>
			<div
				className='menu-link z-[5000] min-[910px]:!hidden'
				ref={menuLink}
				onClick={(e) => {
					if (e.target.classList.contains("menu-link")) {
						handleRemoveMenu();
					}
				}}>
				<ul className=' h-full gap-[20px]  bg-[#f9f9f9] w-[70%] max-w-[100%] relative z-[1002] p-[20px] flex justify-center items-center flex-col'>
					<div
						className=' absolute top-[40px] right-[20px] z-[5005] cursor-pointer'
						onClick={handleRemoveMenu}>
						<HighlightOff className='!text-[32px] !font-[700] !text-main_color_02' />
					</div>

					<NavLink
						to={"/"}
						className=' px[12px] text-color-main-04 tn hover:border-b-[1px] border-color-main-04  w-fit font-[600] cursor-pointer text-[20px]'
						onClick={handleRemoveMenu}>
						الرئيسية
					</NavLink>

					{/*	<NavLink
						to={"https://blog.celebrity.sa/"}
						className=' px[12px] text-color-main-04 tn hover:border-b-[1px] border-color-main-04  w-fit font-[600] cursor-pointer text-[20px]'
						onClick={handleRemoveMenu}>
						المدونة
					</NavLink>*/}

					<NavLink
						to={"/about"}
						className=' px[12px] text-color-main-04 tn hover:border-b-[1px] border-color-main-04  w-fit font-[600] cursor-pointer text-[20px]'
						onClick={handleRemoveMenu}>
						من نحن
					</NavLink>
					<NavLink
						to={"/term"}
						className=' px[12px] text-color-main-04 tn hover:border-b-[1px] border-color-main-04  w-fit font-[600] cursor-pointer text-[20px]'
						onClick={handleRemoveMenu}>
						شروط الاستخدام
					</NavLink>
					<NavLink
						to={"/privacy"}
						className=' px[12px] text-color-main-04 tn hover:border-b-[1px] border-color-main-04  w-fit font-[600] cursor-pointer text-[20px]'
						onClick={handleRemoveMenu}>
						سياسة الخصوصية
					</NavLink>

					<NavLink
						to={"/contact"}
						className=' px[12px] text-color-main-04 tn hover:border-b-[1px] border-color-main-04  w-fit font-[600] cursor-pointer text-[20px]'
						onClick={handleRemoveMenu}>
						اتصل بنا
					</NavLink>
				</ul>
			</div>
		</div>
	);
};

export default Header;
