// import React, { useCallback, useRef, useState } from "react";
// import TitleBox from "../../TitleBox/TitleBox";
// import "./PrivacyBox.css";
// import { useNavigate } from "react-router-dom";
// import { useMemo } from "react";
// import { useEffect } from "react";
// const PrivacyBox = () => {

//     let navigate = useNavigate()
//     let goUpWindow = () => {
//         window.scroll(0, 0);
//     };
//     const [dataShow, setDataShow] = useState([]);
//     const [sliceNumber, setSliceNumber] = useState(2);
//     const dataTarget = useMemo(() => {
//         return () =>
//             [

//                 <div className="content min-h-[357px] w-full py-[43px] px-[15px]  md:px-[58px] flex gap-[43px] flex-col justify-start items-start">

//                     <div className="flex-col items-start justify-start box">

//                         <h6>معلومات المتداولة والخدمات المتبادلة ونوع الإعلانات والعروض المقدمة بالمنصات:
//                         </h6>
//                         <p>
//                             -  منصات المشاهير على اطلاع دائم على الاتفاقيات والتعاقدات الحاصلة في المنصات، وذلك لكون المستخدم (التاجر، المتابع) والمشهور يستخدم بوابات الدفع التي توفّرها منصات المشاهير.
//                             -  منصات المشاهير على إطلاع بأداء المستخدم (التاجر، المتابع) والمشهور وذلك في حال استدعت الحاجة إلى توجيه الدعم أو النصح والإرشاد لمساعدتهم وتحسين جودة التعاقدات.
//                         </p>
//                     </div>

//                 </div>,
//                 <div className="content min-h-[357px] w-full py-[43px] px-[15px]  md:px-[58px] flex gap-[43px] flex-col justify-start items-start">

//                     <div className="flex-col items-start justify-start box">

//                         <h6>مشاركة المعلومات الخاصة بالمستخدمين والمشاهير:
//                         </h6>
//                         <p>
//                             -  بطبيعة الحال فإنّ منصات المشاهير تسعى بالاحتفاظ بهذه المعلومات بما يحفظ خصوصية التاجر، ومنصات المشاهير لا تحتفظ بهذه المعلومات إلا بهدف تحسين جودة المنصات وجودة عمل الصفحات الالكترونية وتسهيلاً وتيسيراً لأعمال التجار والمشاهير.
//                             -  كقاعدة عامة فإنّ جميع هذه المعلومات لا يطلع عليها إلا بعض القائمين على منصات المشاهير وذلك بعد حصولهم على تصريح للاطلاع عليها من قِبل إدارة منصات المشاهير– عادة ما يكون التصريح محدد ومقيّد ويخضع لرقابة قانونية وإدارية من قِبل المنصات – ولن يتم نشر أو بث هذه المعلومات للغير.
//                             - حيث أن منصات المشاهير تسعى للحفاظ على سلامة الصفحات الالكترونية والعلاقة التعاقدية بين حقوق التُجّار والمشاهير، فإنه – في حال ملاحظة منصات المشاهير لأي نشاط غير نظامي أو غير شرعي يقوم به التاجر او المشهور – فإنّ منصات المشاهير تقوم بتطبيق مواد وبنود وأحكام <span className=" bg-[#f6f6f6] underline cursor-pointer" onClick={() => {
//                                 navigate("/home/term")
//                                 goUpWindow();
//                             }}>اتفاقية الاستخدام</span> وحيث أنّها تسعى بقدر الإمكان إلى الحفاظ على سلامة عمل التجار والمشاهير فإنّها قد تشارك أيٍ من هذه المعلومات مع الجهات المختصة لاتخاذ اللازم حيال التاجر أو المشهور المخالف ، وذلك لحماية منصات المشاهير وباقي التُجّار والمشاهير من أي مسائلة قانونية قدّ تطرأ على المنصات أو على أحد مستخدميها نتيجة لهذا النشاط الغير شرعي أو الغير نظامي.

//                         </p>
//                     </div>

//                 </div>,
//                 <div className="content min-h-[357px] w-full py-[43px] px-[15px]  md:px-[58px] flex gap-[43px] flex-col justify-start items-start">

//                     <div className="flex-col items-start justify-start box">

//                         <h6>ما هو مدى أمان سرية المعلومات الخاصة بالتُجّار أو المستهلكين أو المشاهير:
//                         </h6>
//                         <p>
//                             - تسعى منصات المشاهير إلى الحفاظ على سرية معلومات مستخدمين المنصات، وحيث أنّ سياسة الخصوصية الخاصة بالمستخدمين (التاجر، المتابع) أو المشاهير لن تخالف أحكام  <span className=" bg-[#f6f6f6] underline cursor-pointer" onClick={() => { navigate("/home/term"); goUpWindow() }}>اتفاقية الاستخدام</span> أو سياسة الخصوصية وسرية المعلومات. ولكن نظراً لعدم إمكانية ضمان ذلك 100% في (فضاء الإنترنت) فإنّ فريق عمل منصات المشاهير، ينوّه على يلي:

//                             1- تسعى منصات المشاهير إلى الحفاظ على جميع المعلومات وألاّ يطلع عليها أي شخص بما يخالف السياسة المعمول بها في منصات المشاهير.
//                             2- تعمل منصات المشاهير على حماية المعلومات بموجب أنظمة حماية إلكترونية وتقنية ذات جودة عالية وتُحدّث بشكل مستمر ودوري.
//                             3- غير أنّه نظراً لأن شبكة الإنترنت لا يمكن ضمانها 100% لما قد يطرأ من اختراق أو فيروسات على أنظمة الحماية الالكترونية وعلى جدران الحماية المعمول بها في منصات المشاهير فإنّ منصات المشاهير تنصح التجار بالحفاظ على معلوماتهم بسرية تامة.

//                         </p>
//                     </div>

//                 </div>,
//                 <div className="content min-h-[357px] w-full py-[43px] px-[15px]  md:px-[58px] flex gap-[43px] flex-col justify-start items-start">

//                     <div className="flex-col items-start justify-start box">

//                         <h6>الخدمات الاستراتيجية واللوجستية (خدمات المشهور):
//                         </h6>
//                         <p>
//                             - يُقر المستخدم بعلمه التام والنافي للجهالة بأنّه في حال تقدّم لطلب التسجيل في خدمة مُقدّمة عن طريق المنصات، بأنّه يمنح تصريح وتخويل وإذن كامل وتام إلى منصات المشاهير بتزويد مُقدّم الاعلان ببيانات التاجر والمتجر لدى مُقدِم الخدمة، مثل (اسم المستخدم – رقم الجوال – البريد الإلكتروني – رقم الهوية أو الإقامة – السجل التجاري أو ترخيص المتجر). وذلك حتى يتمكّن مقدم الخدمة (المشهور / المنصات) من تقديم الخدمة المطلوبة والتي اشترك بها لدى المنصات.
//                         </p>
//                     </div>

//                 </div>,
//                 <div className="content min-h-[357px] w-full py-[43px] px-[15px]  md:px-[58px] flex gap-[43px] flex-col justify-start items-start">

//                     <div className="flex-col items-start justify-start box">

//                         <h6>الخدمات الاستراتيجية واللوجستية (خدمات التاجر):
//                         </h6>
//                         <p>
//                             - يُقر المشهور بعلمه التام والنافي للجهالة بأنّه في حال تقدّم لطلب التسجيل في خدمة مُقدّمة عن طريق المنصات، بأنّه يمنح تصريح وتخويل وإذن كامل وتام إلى منصات المشاهير بتزويد مُقدّم الاعلان ببيانات المشهور لدى مُقدِم الخدمة، مثل (اسم المستخدم – رقم الجوال – البريد الإلكتروني – رقم الهوية أو الإقامة – السجل التجاري او الترخيص الإعلامي). وذلك حتى يتمكّن مقدم الخدمة (التاجر / المنصات) من تقديم الخدمة المطلوبة والتي اشترك بها لدى المنصات.
//                         </p>
//                     </div>

//                 </div>,
//                 <div className="content min-h-[357px] w-full py-[43px] px-[15px]  md:px-[58px] flex gap-[43px] flex-col justify-start items-start">

//                     <div className="flex-col items-start justify-start box">

//                         <h6>تغيير سياسة الخصوصية وسرية المعلومات:
//                         </h6>
//                         <p>
//                             قد تخضع سياسة الخصوصية هذه للتغيير من وقت لآخر، تم نشر النسخة الحالية من سياسة الخصوصية هذه على الانترنت من قبل منصات المشاهير. يتحتم على المستخدم (تاجر /متابع) –المشهور التحقق من سياسة الخصوصية الخاصة بالمنصات بشكل مستمر واستخدامك لمنصات المشاهير وتطبيقها الالكتروني وخدماتها يشمل موافقتك على سياسة الخصوصية حيث يتم تعديلها من وقت لآخر.
//                         </p>
//                     </div>

//                 </div>
//             ]

//     }, [navigate]);
//     const handleGetData = useCallback(() => {
//         if (dataShow.length !== dataTarget().length) {
//             let data = dataTarget().slice(0, sliceNumber)
//             setDataShow(data);
//             setSliceNumber((prev) => prev + 1)
//         }

//     }, [dataTarget, sliceNumber, dataShow.length]);
//     const getDataRef = useRef(true)

//     useEffect(() => {
//         if (getDataRef.current) {
//             handleGetData()
//             getDataRef.current = false;
//         }
//     }, [handleGetData]);

//     return (
//         <>
//             <div className="privacy-box min-h-[100vh]">
//                 <TitleBox
//                     title={`أهلاً بكم في منصّات "المشاهير"`}
//                     description={`نرحب بكم في منصات المشاهير، ونتقدم بالشكر على ثقتكم بالمنصات، ونفيدكم بأنّه حرصاً منا إدراكنا التام بأنّ المستخدم (تاجر-متابع) والمشهور لهما حقوق معيّنة عبر منصات المشاهير، فإنّ منصات المشاهير تسعى للحفاظ على المعلومات الخاصة بالمستخدم (تاجر-متابع) والمشهور، وفقاً لآلية سياسة الخصوصية وسرية المعلومات المعمول بها في منصات المشاهير. وعليه  فإنّ منصات المشاهير تُنوّه بأنّه وفقاً لاتفاقية الاستخدام المبرمة بين المستخدمين (تاجر-متابع) وبين منصات المشاهير فإنّ هذه الوثيقة تُحيطكم علماً بسياسة الخصوصية وسرية المعلومات المعمول بها في منصات المشاهير، وأنّه وفقاً لـ (قواعد وأحكام استخدام منصات المشاهير) من اتفاقية الاستخدام فقد أنشأت منصات المشاهير هذه القواعد “سياسة الخصوصية وسرية المعلومات” لتوضيح وتحديد آلية السرية والخصوصية المعمول بها في منصات المشاهير الالكترونية، ويرجى اطلاعكم عليها حيث أنكم بتسجيلكم في منصات المشاهير وتأسيسكم للملف والصفحة الإلكترونية فإنّ جميع معلوماتكم تخضع لهذه السياسة.`}
//                 />
//                 <div className="container h-full flex justify-center flex-col gap-[28px] items-center mt-[48px]">

//                     {dataShow.length ? (
//                         dataShow.map((el) => {
//                             return el

//                         })

//                     ) : null}
//                     {dataShow.length !== dataTarget().length ? <button className=" ml-auto py-[10px] px-[20px] bg-color-main-02 text-white text-[17px]" onClick={handleGetData}> عرض المزيد...</button> : null}

//                 </div>
//             </div>
//         </>
//     );
// };

// export default PrivacyBox;

import React from "react";
import TitleBox from "../../TitleBox/TitleBox";
import "./PrivacyBox.css";
import { useNavigate } from "react-router-dom";
import { useCallback } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { useMemo } from "react";
import { useState } from "react";
const PrivacyBox = () => {
	let navigate = useNavigate();
	const [dataShow, setDataShow] = useState([]);
	const [sliceNumber, setSliceNumber] = useState(2);

	const dataTarget = useMemo(() => {
		return () => [
			<div className='flex-col items-start justify-start box'>
				<h6> [1] المقدمة</h6>
				<p>
					1.1. يتطلب تقديم خدماتُنا جمع واستخدام ومعالجة وتخزين البيانات
					الشخصيّة على منصّات "المشاهير"، ونظرًا لأنّ البيانات الشخصيّة تتمتّع
					بأهميّة خاصة مُستخدِمي المنصّات، فإنّنا نتعاملُ معها بقدرٍ كبيرٍ من
					السرية والخصوصيّة. لذا نرجو منك قراءة سياسة الخصوصيّة هذه بعنايةِ
					تامةِ قبل استخدام المنصّات.
				</p>
				<p>
					1.2.تُشكل هذه السياسة عقدًا قانونيًا ملزمًا بينك وبين مؤسسة منصّات
					المشاهير للتسويق الإلكتروني، المالك والمُشغل لمنصّات "المشاهير" والتي
					تقدّم خدماتها من خلال الموقع https://celebrity.sa وتطبيقات للجوّال
					يمكن تحميلها من متجر أندرويد أو متجر آبل. لذا يجب عليك قراءة جميع
					بنودها بعنايةٍ تامةٍ قبل استخدام خدمات المنصّات.
				</p>
				<p>
					1.3.وضعت هذه السياسة من أجل تقديم أفضل الخدمات لمُستخدِمي المنصّات،
					وتوضيح المنهجيّة التي نتّبِعُها في منصّات "المشاهير" عند جمع البيانات،
					واستخدامُها، ومعالجتُها، وتخزينُها، والخطوات المُتّخذَة لتأمينها،
					وبيان حقوق المُستخدِمين التي كفلها نظام حماية البيانات الشخصيّة
					ولائحته التنفيذية.
				</p>
				<p>
					1.4.بوصولك وتسجيلك حساب في منصّات "المشاهير" واستخدام خدماتها، يعني
					أنّك اطّلعت على سياسة الخصوصيّة هذه وأنّك مُلمٌ بكافة الأحكام المتعلقة
					بها، وأنّك توافق على الالتزام بكافة بنودها اعتبارًا من تاريخ سريانها؛
					لا يجوز لك استخدام المنصّات إلا إذا كنت توافق على الممارسات الموضّحة
					في هذه السياسة.
				</p>
			</div>,
			<div className='flex-col items-start justify-start box'>
				<h6>[2] التعريفات</h6>
				<p>
					يكون للكلمات التالية المعنى المبين بجانب كُلٍّ منها، ما لم يقتضِ
					السياقُ خلاف ذلك:
				</p>
				<p>2.1."المنصّات": يُقصد بها منصّات "المشاهير".</p>
				<p>
					2.2."نحن"، "لنا" أو "لدينا": يُقصد بها مؤسسة منصّات المشاهير للتسويق
					الإلكتروني، ورقم السجل التجاري: (4030445660) وعنوانها: (المملكة
					العربيّة السعوديّة، مدينة جدة، حي الرويس، طريق المدينة المنورة).
				</p>
				<p>
					2.3."أنت" أو "لك": يُقصد به كل من يَستخدِم منصّات "المشاهير" سواء مؤثر
					أو تاجر.
				</p>
				<p>
					2.4."البيانات الشخصيّة": يُقصد بها كل بيان -مهما كان مصدره أو شكله- من
					شأنه أن يؤدي إلى معرفة الفرد على وجه التحديد، أو يجعل التعرّف عليه
					ممكنًا بصفة مباشرة أو غير مباشرة، ومن ذلك الاسم، ورقم الهويّة
					الشخصيّة، والعناوين، وأرقام التواصل، وغير ذلك من البيانات ذات الطابع
					الشخصي.
				</p>
				<p>
					2.5."المُعالجة": يُقصد بها أيّ عملية تُجرى على البيانات الشخصيّة بأيّ
					وسيلة كانت يدوية أو آلية.
				</p>
				<p>
					2.6."الغرض المشروع": يُقصد به الغرض الذي تتم لأجله معالجة البيانات
					الشخصيّة للفرد طبقًا للقانون.
				</p>
				<p>
					2.7."الأطراف الثالثة": يُقصد بها كافة الأشخاص والجهات الأخرى غير
					التابعة لنا التي قد تشاركنا في تقديم الخدمات أو التي قد توفر خدماتها
					من خلال منصّات "المشاهير".
				</p>
				<p>
					2.8."السياسة": يُقصد بها سياسة الخصوصيّة هذه وما تتضمنه من توضيحات
					للبيانات التي يتم جمعها واستخدامها ومعالجتها وطريقة التعامل معها على
					منصّات "المشاهير".
				</p>
				<p>
					2.9."القوانين": يُقصد بها الأنظمة واللوائح والقرارات والمراسيم السارية
					في المملكة العربيّة السعوديّة.
				</p>
			</div>,

			<div className='flex-col items-start justify-start box'>
				<h6>[3] نطاق السياسة</h6>

				<p>
					3.1.تنطبقُ هذه السياسة على كافة استخداماتكم لمنصّات "المشاهير" بما في
					ذلك المعلومات والبيانات والخدمات والتطبيقات وجميع الصفحات والأنشطة
					الأخرى التي نقدمها على المنصّات أو من خلال الخدمات المتاحة عليها أو
					عندما التفاعل معنا بأي طريقة أخرى، ولا تنطبق هذه السياسة على المواقع
					أو التطبيقات أو الخدمات التي تقدمها الجهات الخارجية الأخرى التي ترتبط
					بخدماتنا.
				</p>
				<p>
					3.2.تتناول هذه السياسة على وجه التحديد التزاماتنا فيما يتعلق بالأنظمة
					السارية في المملكة العربيّة السعوديّة، ولن يتم استخدام بياناتكم
					الشخصيّة التي تقدمونها إلا بالطرق الموضّحة في هذه السياسة، والتي تتضمن
					أيضًا استخدام ملفات تعريف الارتباط على الإنترنت.
				</p>
			</div>,

			<div className='flex-col items-start justify-start box'>
				<h6>[4] جمع البيانات</h6>

				<p>
					4.1. نستخدم طرقًا مختلفة لجمع البيانات عند استخدام منصّات "المشاهير"،
					منها على سبيل المثال:
					<div class='sub-list'>
						<p>
							4.1.1. البيانات التي تقدمها لنا مباشرة
							<div class='sub-list'>
								<p>
									4.1.1.1. بيانات تسجيل حساب في المنصّات وإكمال الملف التعريفي،
									وتشمل:
									<div class='sub-list'>
										<p>
											• البيانات التي يقدمها المؤثر أو المشهور عند تسجيل حساب،
											مثل الاسم الكامل، البريد الإلكتروني، رقم الجوّال، كلمة
											المرور، العنوان، الصورة الشخصيّة، رقم موثوق، حسابات
											التواصل الاجتماعي، عدد المتابعين لكل حساب، وأي بيانات أخرى
											تطلبها المنصّات.
										</p>
										<p>
											• البيانات التي يقدمها التاجر عند تسجيل حساب، مثل الاسم،
											البريد الإلكتروني، رقم الجوّال، كلمة المرور، العنوان،
											الموقع الإلكتروني، السجل التجاري، نشاط التاجر، رقم الحساب
											البنكي لاستلام الأرباح، وأي بيانات أخرى تطلبها المنصّات.
										</p>
										<p>
											• البيانات التي يقدمها المُستخدِم النهائي عند تسجيل حساب،
											مثل الاسم، والبريد الإلكتروني، ورقم الجوّال.
										</p>
									</div>
								</p>
								<p>
									4.1.1.2. بيانات تسجيل الدخول إلى الحساب، مثل اسم المستخدم
									وكلمة المرور.
								</p>
								<p>
									4.1.1.3. بيانات التواصل، مثل البيانات التي تقدمها عند التواصل
									معنا عن طريق البريد الإلكتروني، أو التي تزودنا بها عندما
									تواجهك صعوبة أو مشكلة عند استخدام المنصّات أو خدماتها.
								</p>
								<p>
									4.1.1.4. البيانات المالية، مثل بيانات شحن الحساب لطلب خدمة من
									المؤثر عن طريق المنصّات، أو البيانات التي يقدمها المؤثر لتسهيل
									عملية استلام أرباحه من الإعلانات. يرجى العلم أن هذه البيانات
									سيتم جمعها عن طريق مزوّد خدمات المدفوعات لأغراض معالجة عملية
									الدفع والتحويل والتحقق منها.
								</p>
								<p>
									4.1.1.5. البيانات التي تقرر مشاركتها بإرادتك في أي وقت عند
									استخدام خدمات المنصّات.
								</p>
							</div>
						</p>
						<p>
							4.1.2. البيانات التي تقدمها عند طلب الخدمات
							<div class='sub-list'>
								<p>
									4.1.2.1. البيانات التي يقدمها التاجر عند طلب خدمة من المؤثر،
									مثل نوع الخدمة، ووصف مختصر ودقيق عن الخدمة، والأعمال التي يرغب
									في الإعلان عنها من خلال المؤثر، وغيرها من البيانات.
								</p>
								<p>
									4.1.2.2. البيانات التي يقدمها المؤثر عند الخدمات التي يقدمها،
									مثل اسم ونوع كل خدمة، وصف الخدمة، سعر الخدمة، أكواد الخصم،
									المتطلبات التي يجب على التاجر تقديمها لتنفيذ الخدمة، وغيرها من
									البيانات.
								</p>
								<p>
									4.1.2.3. البيانات التي تتعلق بالطلبات، مثل رقم الطلب، تاريخ
									ووقت الطلب، اسم المؤثر، نوع الإعلان، وقت الإعلان، تاريخ
									التسليم، السعر الإجمالي للفاتورة، وسيلة الدفع المستخدمة،
									وغيرها من البيانات.
								</p>
							</div>
						</p>
						<p>
							4.1.3. البيانات التي نجمعها تلقائيًا
							<div class='sub-list'>
								<p>
									4.1.3.1. البيانات التقنيّة التي تتعلق باستخدامك للمنصّات، مثل
									عنوان بروتوكول شبكة الإنترنت (اي بي) لربط جهازك بشبكة
									الانترنت، ومزوّد خدمة الإنترنت، ومعرّفات الجهاز، ومعرّف
									الإعلان، ومعرّفات ملفات تعريف الارتباط، والمعرّفات الفريدة
									الأخرى، وتفاصيل حول متصفحك، والتاريخ والوقت، والصفحات التي قمت
									بزيارتها، ومعلومات حول الروابط التي تنقر عليها، ومعلومات أخرى
									حول كيفية استخدامك لخدماتنا.
								</p>
								<p>
									4.1.3.2. البيانات التي تتعلق بجهازك المُستخدم في الوصول إلى
									خدمات المنصّات، مثل نوع الجهاز، واسم نظام التشغيل والإصدار،
									والشركة المصنّعة للجهاز والطراز، واللغة، ودقة الشاشة، وغيرها.
								</p>
								<p>
									4.1.3.3. البيانات التي تتعلق بسجلات التصفح، وإحصائيات الصفحات،
									والبيانات الأخرى المتعلقة بالزيارات والزوّار، وأدوات تحليلات
									الاستخدام الداخلي، مثل معلومات عدد النقرات والتمريرات والميزات
									التي يتم الوصول إليها، وتاريخ ووقت الوصول، والمدة، والأخطاء
									الناشئة، وبيانات الأداء، وسعة التخزين، وإعدادات الأجهزة
									المستخدمة للوصول إلى المنصّات.
								</p>
								<p>
									4.1.3.4. البيانات التي تتعلق بتقنيات التتبع، مثل البيانات التي
									يتم جمعها باستخدام الكوكيز، وإشارات الويب، وعلامات البيكسل،
									والبرامج النصيّة، والتقنيات الأخرى المماثلة.
								</p>
							</div>
						</p>
						<p>
							4.1.4. البيانات التي قد نجمعها من مصادر أخرى
							<div class='sub-list'>
								<p>
									4.1.4.1. البيانات التي يقدمها لنا شركاؤنا من مزوّدي الخدمات
									عند تقديم جزء من خدمات منصّات "المشاهير"، مثل شركاء التسويق،
									والإعلانات، وقواعد البيانات العامة وغيرها من الجهات والمصادر
									الأُخرى.
								</p>
							</div>
						</p>
					</div>
				</p>
				<p>
					4.2. تعتمد البيانات الشخصيّة التي نجمعها عنك على الأنشطة المعينة التي
					يتم تنفيذها من خلال استخدام خدماتنا، إذا قمت بتقديم أيّ بيانات تتعلق
					بأشخاص آخرين إلينا أو لمقدمي الخدمات و/أو الشركاء و/أو المرتبطين بنا
					فيما يتعلق بالخدمات، فأنت تقر بأن لديك السلطة للقيام بذلك وتسمح لنا
					باستخدام البيانات وفقًا هذه السياسة.
				</p>
			</div>,

			<div className='flex-col items-start justify-start box'>
				<h6>[5] استخدام البيانات</h6>

				<p>
					نحن ملتزمون دائمًا بحماية خصوصيّتك، وبالتالي نستخدم بياناتك الشخصيّة
					للأغراض الآتية:
				</p>
				<p>5.1. للمساعدة في تسجيل الحسابات، وتحديد هويّة مُستخدِمي المنصّات.</p>
				<p>
					5.2. لتلقي الأسئلة والشكاوى، والرد على استفسارات وأسئلة مُستخدِمي
					المنصّات، والتواصل معهم عند الضرورة.
				</p>
				<p>5.3. لتزويدك بالمعلومات عن الخدمات التي يقدمها المؤثرين.</p>
				<p>
					5.4. لتمكين المؤثر من تقديم خدماته وعرضها على التجار من خلال المنصّات.
				</p>
				<p>
					5.5. لتسهيل عملية التواصل بين المؤثر والتاجر والاتفاق على تنفيذ
					الخدمات.
				</p>
				<p>
					5.6. لمعالجة المدفوعات واستلام الأرباح، ومشاركة البيانات مع بوابات
					الدفع.
				</p>
				<p>
					5.7. للحصول على الطلبات الخاصة بالأطراف لتأكيد إصدار الفواتير من خلال
					المنصّات، والسماح للنظام الآلي بالمنصّات من إصدار الفواتير المتعلقة
					بعمليات شراء الخدمات.
				</p>
				<p>
					5.8. لإرسال الرسائل الإدارية، والتنبيه بتحديثات شروط الاستخدام وسياسة
					الخصوصيّة.
				</p>
				<p>5.9. لاكتشاف الأخطاء والمشاكل التشغيليّة وإصلاحها.</p>
				<p>
					5.10. لمساعدتنا في التشغيل السليم للمنصّات، وتعزيز أمن البيانات، ومنع
					الاحتيال، وإساءة الاستخدام.
				</p>
				<p>
					5.11. للتعريف بخدماتنا وتطويرها والتسويق لها، ومراقبة أداءها،
					وتأمينها.
				</p>
				<p>
					5.12. لقياس أو فهم فعاليّة الإعلانات التي نُقدمها لمُستخدِمي المنصّات،
					وتقديم الإعلانات ذات الصلة.
				</p>
				<p>
					5.13. للامتثال لالتزاماتنا القانونية، وتنفيذ شروطنا وسياساتنا،
					والإجراءات والمتطلبات القانونية المعمول بها.
				</p>
				<p>
					5.14. لحماية أمن وسلامة المنصّات، بما في ذلك إجراء تحليلات أمنية
					للتحقق من أن الخدمات تعمل بشكل صحيح ولم يتم اختراقها.
				</p>
				<p>
					5.15. لتحليل الاتجاهات والإحصاءات، وعمليات التدقيق، وإجراء أبحاث السوق
					والدراسات الاستقصائية.
				</p>
				<p>
					5.16. لأي استخدامات أخرى بعد الحصول على موافقتك لأغراض أخرى غير تلك
					التي كنت قد وافقت عليها سابقًا.
				</p>
			</div>,

			<div className='flex-col items-start justify-start box'>
				<h6>[6] مشاركة البيانات</h6>

				<p>
					6.1. نحن لا نقوم ببيع أو المتاجرة أو تأجير بيانات الهوية الشخصيّة
					لمُستخدِمي المنصّات إلى جهات أخرى، ويجوز لنا مشاركة البيانات الشخصيّة
					التي نجمعها وفقًا لهذه السياسة مع الشركات التابعة لنا ومع الأطراف
					الثالثة الأخرى التي قد تكون ضرورية لتحقيق الأغراض المنصوص عليها في
					القسم [5] من هذه السياسة، وبناءً عليه فأنت تقر وتوافق بأنه يحق لنا
					مشاركة بياناتك الشخصيّة على النحو التالي:
					<div className='sub-list'>
						<p>
							6.1.1. مع الجهات قضائية أو الحكومية من أجل تلبية القوانين، أو
							التشريعات، أو الإجراءات القانونيّة، أو المَطالب الحكوميّة المعمول
							بها، أو لممارسة حقوقنا القانونيّة أو ترسيخها أو الدفاع عنها، وذلك
							دون أدنى مسؤولية على المنصّات، ولا يحق لك طلب أي تعويض جراء ذلك.
						</p>
						<p>
							6.1.2. لتحقيق مصالحنا المشروعة، وحماية حقوقنا وخصوصيتنا وسلامتنا
							وممتلكاتنا وحقوق الشركات التابعة لنا، وحقوقك، وحقوق أي أطراف أخرى،
							ولأغراض الحماية من الاحتيال المشتبه به، أو الأنشطة غير القانونيّة،
							أو أيّ مواقف تنطوي على تهديدات محتملة لسلامة أيّ شخص.
						</p>
						<p>
							6.1.3. مع الأطراف الثالثة الذين يقدمون خدمات أو وظائف نيابة عنا،
							بما في ذلك تحليلات الأعمال، وخدمة العملاء، والتسويق، والعلاقات
							العامة، وتوزيع الاستطلاعات، ومنع الاحتيال. قد نأذن أيضًا لمورّدي
							الجهات الخارجية بتجميع المعلومات نيابةً عنا، بما في ذلك على سبيل
							المثال، عند الضرورة لتشغيل ميزات المنصّات أو لتسهيل تقديم
							الإعلانات عبر الإنترنت المصممة خصيصًا لمصالحك. ويمتلك موردو الجهات
							الخارجية حق الوصول إلى المعلومات ويجوز لهم جمعها -عند الحاجة-
							لأداء وظائفهم.
						</p>
					</div>
				</p>
				<p>
					6.2. أنت توافق على منحنا حق السماح لموظفينا وشركائنا من الأطراف
					الثالثة بالتعامل مع بياناتك الشخصيّة في حدود تقديم خدماتنا. يرجى العلم
					أن استخدام بياناتك من قبل أيّ أطراف ثالثة سيخضع لسياسات الخصوصيّة
					الخاصة بهذه الأطراف؛ لذا نوصيك بمراجعتها لمعرفة الممارسات التي تتم
					عليها.
				</p>
			</div>,

			<div className='flex-col items-start justify-start box'>
				<h6>[7] الإفصاح القانوني</h6>

				<p>
					في حالة بيع جزء من الأعمال أو الأصول إلى كيان أخر أو أيّ انتقال أو
					تغيير في أعمالنا، يجوز لنا مشاركة البيانات الشخصيّة مع الكيان الجديد
					فيما يتعلق بأيّ عملية إعادة هيكلة، أو اندماج، أو استحواذ، أو إعادة
					تنظيم، أو بيع الأصول، أو مشروع مشترك، أو التنازل، أو التحويل، أو
					الإفلاس، أو التصفية، أو الحراسة القضائية، أو أي تصرف مشابه، ويجوز
					للكيان الجديد استخدام بياناتك الشخصيّة بالطريقة نفسها المنصوص عليها في
					هذه السياسة.
				</p>
			</div>,

			<div className='flex-col items-start justify-start box'>
				<h6>[8] تخزين البيانات</h6>

				<p>
					8.1. نخزن بياناتك الشخصيّة طالما أنها ضرورية للأغراض التي تتم بها أو
					من خلالها معالجة البيانات الشخصيّة والخاصة بها. كما سنحتفظ ببيانات
					الاستخدام لأغراض التحليل الداخلي، ويتم الاحتفاظ بهذه البيانات لفترة
					زمنية أقصر إلا في حالة استخدامها لتعزيز الأمان أو لتحسين وظائف
					خدماتنا، وإنفاذ سياساتنا القانونية.
				</p>
				<p>
					8.2. تتضمن المعايير التي تحدد فترات الاحتفاظ بالبيانات ما يلي:
					<div className='sub-list'>
						<p>8.2.1. النظر إلى طبيعة وحساسية البيانات الشخصيّة التي نجمعها.</p>
						<p>8.2.2. مدة تزويدك بالخدمات أو تشغيل أعمالنا.</p>
						<p>
							8.2.3. المُتطلبات القانونيّة السارية، مثل الأوامر الحكومية لأغراض
							التحقيق أو التقاضي، أو الحماية من دعوى محتملة.
						</p>
					</div>
				</p>
			</div>,

			<div className='flex-col items-start justify-start box'>
				<h6>[9] حماية وأمن البيانات</h6>

				<p>
					9.1. يعد أمان بياناتك أمرًا مهمًا بالنسبة لنا، لذا نسعى لحماية هذه
					البيانات والحفاظ عليها من العمليات غير القانونيّة (مثل مخاطر التدمير،
					أو الفقد، أو التغيير غير المقصود، أو الإفصاح غير المرغوب فيه، أو
					الوصول غير المصرّح بها) ويشمل ذلك استخدام جدران حماية وخوادم آمنة
					وكلمات مرور للوصول إليها. وعلى الرغم من أننا سوف نبذل كل ما في وسعنا
					لحماية البيانات الشخصيّة، إلا أنّ إرسال أيّ بيانات عبر الإنترنت دائمًا
					ما ينطوي على مخاطر، وبالتالي لا يُمكننا ضمان أمن بياناتك المُرسَلَة
					عبر الإنترنت.
				</p>
				<p>
					9.2. نتخذ كافة التدابير التقنيّة والفنيّة (مثل التشفير والسجلات وتقييد
					الوصول وحماية البيانات وغيرها) والطبيعة التنظيميّة المناسبة (مثل
					التعليمات الموجهة لموظفينا واتفاقيات السرية وما إلى ذلك) لحماية
					والحفاظ على البيانات الشخصيّة من أي عمليات غير قانونيّة.
				</p>
				<p>
					9.3. نعمل على حماية البيانات الشخصيّة من خلال اتخاذ الإجراءات المناسبة
					التي تتوافق مع القوانين والأنظمة ذات الصلة الخاصة بحماية البيانات، بما
					في ذلك مطالبة شركاء الأعمال الذين نتعامل معهم لاستخدام الإجراءات
					المناسبة لحماية سرية وأمن بياناتك.
				</p>
				<p>
					9.4. نلتزم بالحفاظ على سرية بياناتك الشخصيّة، ونتعهد في حدود المسموح
					به قانونًا بعدم استخدامها أو الإفصاح عنها بما يتعارض مع هذه السياسة،
					ولمساعدتنا في حماية بياناتك الشخصيّة، يجب عليك دائمًا الحفاظ على أمان
					بيانات حسابك وعدم مشاركتها مع أيّ أحد تحت أيّ ظرف.
				</p>
			</div>,

			<div className='flex-col items-start justify-start box'>
				<h6>[10] مسؤولياتك</h6>

				<p>
					10.1. أنت تقرُ بالحفاظ على سريّة وخصوصيّة بياناته الشخصيّة التي
					يقدِمها لنا على منصّات "المشاهير"، كما تقرُ بأنّ الإفصاح عنها إلى أي
					طرف آخر يكون على مسئوليّتك الشخصيّة دون مسؤولية أو ضمانات من المنصّات.
				</p>
				<p>
					10.2. أنت تقرُ وتوافق بأنّ البيانات التي تقدمها على المنصّات تكون على
					مسؤوليتك الشخصية، كما تتحمّل المسؤولية القانونية في حالة تقديمك
					لبيانات كاذبة أو مضللة أو غير مفوض بتقديمها.
				</p>
				<p>
					10.3. أنت تقرُ بأنّ منصّات "المشاهير" لا تُسيطر إلا على البيانات التي
					يتم جمعها من خلال المنصّات أو الخدمات.
				</p>
				<p>
					10.4. يحظر عليك الإفصاح عن أي بيانات تتعلق بتشغيل المنصّات إلى أي طرف
					ثالث سواء كان بمقابل أو بدون مقابل، ويجب عليك تجنب اتباع أي وسيلة من
					شأنها مساعدة الغير على جمع البيانات من المستخدمين الآخرين.
				</p>
				<p>
					10.5. يقرُ يوافق المؤثر بأن لديه رخصة موثوق سارية من الجهات المختصة في
					المملكة العربية السعودية، ولا تتحمل منصّات "المشاهير" ولا التاجر أي
					مسؤولية فيما يتعلق بهذه الرخصة أو انتهائها.
				</p>
				<p>
					10.6. أنت تقرُ وتوافق بتحمّل المسؤوليّة القانونيّة في حالة مشاركة أيّ
					بيانات تتعلق أو ترتبط بشخص آخر، ويجب عليك إعلام هذا الشخص بمحتوى هذه
					السياسة والحصول على موافقته المسبقة.
				</p>
				<p>
					10.7. لن تتحمّل منصّات "المشاهير" مسؤولية تقصيرك أو فشلك في الحفاظ على
					سرية بياناتك أو أيّ تصرفات ينتج عنها الوصول إلى بياناتك من قبل أطراف
					أخرى.
				</p>
			</div>,

			<div className='flex-col items-start justify-start box'>
				<h6>[11] حقوقك</h6>

				<p>
					يكون لك وفقًا للأحكام الواردة في نظام حماية البيانات الشخصيّة السعوديّ
					الصادر بالمرسوم الملكي رقم (م/19) وتاريخ 9/2/1443هـ، وتتضمن هذه الحقوق
					ما يلي:
				</p>
				<p>
					11.1. حق العلم: ويشمل ذلك إحاطتك علمًا بكيفية جمع وتخزين ومعالجة
					بياناتك الشخصيّة والغرض من ذلك.
				</p>
				<p>
					11.2. حق الوصول: ويشمل ذلك تزويدك بنسخة من البيانات التي قمنا
					بمعالجتها بصيغة واضحة دون مقابل مادي.
				</p>
				<p>
					11.3. حق التعديل: ويشمل ذلك تصحيح أو إكمال أو تحديث البيانات الشخصيّة.
				</p>
				<p>
					11.4. حق الاتلاف: ويشمل ذلك إتلاف أو حذف البيانات الشخصيّة إذا انتهت
					الحاجة إليها.
				</p>
				<p>
					11.5. حق الاعتراض: ويشمل ذلك الاعتراض على أنواع معينة من المعالجة مثل:
					تجنب التسويق المباشر.
				</p>
				<p>
					11.6. حق الشكوى: ويشمل ذلك تقديمك شكوى في أي وقت عن طريق البريد
					الإلكتروني.
				</p>
			</div>,

			<div className='flex-col items-start justify-start box'>
				<h6>[12] الامتثال للجهات التنظيمية</h6>

				<p>
					12.1. نراجع بانتظام التزاماتنا بسياسة الخصوصيّة لمنصّات "المشاهير"،
					وعندما نتلقى شكاوى رسمية مكتوبة فإننا نتّصل بالشخص الذي قدم الشكوى
					للمتابعة معه، كما نعمل مع الجهات التنظيمية المعنيّة بما فيها جهات
					حماية البيانات لحل أيّ شكاوى تتعلق بنقل البيانات الشخصيّة التي لا
					يمكننا حلّها مع مستخدمينا مباشرةً.
				</p>
				<p>
					12.2. أنت تقرُ وتوافق وتلتزم بعدم مخالفة أي من أحكام نظام الجرائم
					المعلوماتية السعودي، وفي حال المخالفة فإن هذا يخضع لمسئولتيك الشخصيّة
					دون أي مسؤولية منصّات "المشاهير"، ويحق لإدارة المنصّات اتخاذ ما تراه
					مناسبًا تجاهك أو أي مُستخدِم يخالف أحكام نظام الجرائم المعلوماتية
					السعودي، سواءً عن طريق إبلاغ السلطات الرسمية أو الاكتفاء بإغلاق
					الحساب.
				</p>
			</div>,

			<div className='flex-col items-start justify-start box'>
				<h6>[13] روابط الأطراف الخارجية</h6>

				<p>
					13.1. قد تحتوي منصّات "المشاهير" على روابط لمواقع إلكترونية تابعة جهات
					خارجية، ويتم توفير أي بيانات شخصيّة تقدمها على مواقع الجهات الخارجية
					مباشرة إلى مشغلي هذه الخدمات وتخضع لسياسات المشغلين - إن وجدت- التي
					تحكم الخصوصيّة والأمان، حتى لو تم الوصول إليها من خلال منصتنا.
				</p>
				<p>
					13.2. لا تتحمّل مؤسسة منصّات المشاهير للتسويق الإلكتروني أي مسؤولية عن
					أي سياسات أو ممارسات تستخدمها المواقع الإلكترونية أو الخدمات المرتبطة
					بمنصّات "المشاهير"، ويشمل ذلك المعلومات أو المحتوى الذي تشتمل عليه أو
					ممارسات تجميع البيانات أو سياسات الخصوصيّة التي تطبقها.
				</p>
				<p>
					13.3. يجب عليك مراجعة سياسة الخصوصيّة وشروط الاستخدام الخاصة بالطرف
					الثالث عند زيارة أي روابط للأطراف الخارجية.
				</p>
			</div>,

			<div className='flex-col items-start justify-start box'>
				<h6>[14] الرسائل التسويقية</h6>

				<p>
					14.1. قد نرسل إليك رسائل تسويقية عن طريق البريد الإلكتروني، منها على
					سبيل المثال، قد نرسل إليك معلومات عن خدمات منصّات "المشاهير"، بالإضافة
					إلى تحديثات شروط الاستخدام وسياسة الخصوصية والإشعارات الفنية وتنبيهات
					الأمان ورسائل الدعم والرسائل الإدارية.
				</p>
				<p>
					14.2. يمكنك إلغاء الاشتراك في استلام هذه الرسائل التسويقية في المستقبل
					في أي وقت عن طريق تتبُّع رابط إلغاء الاشتراك الموجود داخل الرسالة
					الإلكترونية.
				</p>
			</div>,

			<div className='flex-col items-start justify-start box'>
				<h6>[15] ملفات تعريف الارتباط</h6>

				<p>
					15.1. قد تستخدم منصّات "المشاهير" ملفات تعريف الارتباط أو أي تقنيات
					أخرى لتذكُر البيانات الشخصيّة أو المعلومات العامة عند استخدامك
					لخدماتنا.
				</p>
				<p>
					15.2. ملفات تعريف الارتباط هي أجزاء صغيرة من المعلومات الإلكترونية
					التي يرسلها موقع الويب إلى متصفحك، ويتم تخزينها على القرص الصلب للزائر
					مثل العديد من المواقع الإلكترونية.
				</p>
				<p>
					15.3. نستخدم ملفات تعريف الارتباط للسماح لنا بتقديم معلومات لجعل
					تجربتك عبر الإنترنت أكثر ملاءمة، وتزويدك بتجربة ذات طابع شخصي، وتعزيز
					الأمان، وتسهيل التنقل، وعرض المواد بكفاءة أكبر، والتعرف على جهازك عند
					الوصول إلى الخدمات، وفهم كيفية استخدامها، وتتبع واستهداف اهتماماتك،
					وإعداد التقارير، وتقييم التوجهات، وذلك من أجل تحديد الإعلانات ذات
					الصلة القائمة على الاهتمامات لخدمة العملاء ورصد الطريقة التي يتم بها
					استخدام خدماتنا.
				</p>
				<p>
					15.4. قد نستخدم أيضًا الخدمات التي تستضيفها جهات خارجية، للمساعدة في
					هذه الأمور، والتي قد تجمع بيانات، مثل عنوان بروتوكول الإنترنت الخاص
					بك، الذي يرسله متصفحك أو عبر ملفات تعريف الارتباط. ويتمثل الهدف من هذا
					في تعزيز الملاءمة والراحة لك، ومساعدتنا على تحقيق الكفاءة في أعمالنا.
				</p>
				<p>
					15.5. يمكنك اختيار تعطيل تخزين ملفات الكوكيز على جهازك عن طريق تغيير
					إعدادات متصفحك، ومع ذلك قد يؤثر تعطيل ملفات الكوكيز سلبًا في تحديد
					تجربتك مع خدماتنا، وقد لا تتمكن من استخدام كافة المزايا التي نوفرها.
				</p>
			</div>,

			<div className='flex-col items-start justify-start box'>
				<h6>[16] التعديلات</h6>

				<p>
					16.1. يحق لنا تعديل سياسة الخصوصية هذه في أي وقت، لتعكس أيّ تغييرات في
					الأنظمة السارية، أو ممارسات جمع واستخدام البيانات، أو ميزات خدماتنا،
					أو التطورات التكنولوجية، وفي حال قمنا بإجراء تغييرات جوهرية على هذه
					السياسة، فسنقوم بنشرها في هذه الصفحة، وتحديث تاريخ السريان وتقديم
					إشعار وفقًا للمتطلبات القانونية.
				</p>
				<p>
					16.2. تسري جميع التغييرات فور نشرها في هذه الصفحة، ويعد استخدامك
					لخدمات منصّات "المشاهير" بعد نشر النسخة المحدّثة من هذه السياسة موافقة
					منك على جميع هذه التغييرات. يرجى التنويه بأننا لسنا مسؤولين عن عدم
					مراجعتك هذه السياسة قبل استخدام خدماتنا.
				</p>
			</div>,

			<div className='flex-col items-start justify-start box'>
				<h6>[17] الموافقة والإقرارات</h6>

				<p>
					17.1. أنت تٌقر بأنك قرأت سياسة الخصوصية هذه وتوافق على جميع بنودها
					وشروطها.
				</p>
				<p>
					17.2. أنت تٌقر بأن استمرارك في استخدام خدمات منصّات "المشاهير" موافقة
					صريحة على جمع واستخدام ومعالجة ومشاركة بياناتك، وموافقة على الالتزام
					بهذه السياسة، والضوابط والشروط التي تحكم استخدام خدماتنا.
				</p>
			</div>,

			<div className='flex-col items-start justify-start box'>
				<h6>[18] الاتصال بنا</h6>

				<p>
					إذا كان لديك أي أسئلة أو استفسارات حول سياسة الخصوصيّة، يرجى التواصل
					معنا على:
				</p>
				<p>
					<a href='mailto:info@celebrity.sa'>info@celebrity.sa</a>
				</p>
				<p>
					<a href='tel:9660506340450+'>9660506340450+</a>
				</p>
				<p>
					جميع الحقوق محفوظة © 2024 منصّات "المشاهير" | مؤسسة منصّات المشاهير
					للتسويق الإلكتروني
				</p>
			</div>,
		];
	}, [navigate]);

	// handle show more...
	const handleGetData = useCallback(() => {
		if (dataShow.length !== dataTarget().length) {
			let data = dataTarget().slice(0, sliceNumber);
			setDataShow(data);
			setSliceNumber((prev) => prev + 1);
		}
	}, [dataTarget, sliceNumber, dataShow.length]);
	const getDataRef = useRef(true);

	useEffect(() => {
		if (getDataRef.current) {
			handleGetData();
			getDataRef.current = false;
		}
	}, [handleGetData]);
	return (
		<>
			<div className='term-box min-h-[100vh]'>
				<TitleBox
					title='سياسة الخصوصية'
					subTitle='أهلاً بكم في منصّات "المشاهير"'
				/>
				<div className='container h-full flex justify-center items-center mt-[48px]'>
					<div className='content min-h-[681px] w-full py-[43px] px-[15px] md:px-[58px] flex gap-[43px] flex-col justify-start items-start'>
						{dataShow.length
							? dataShow.map((el) => {
									return el;
							  })
							: null}
						{dataShow.length !== dataTarget().length ? (
							<button
								className=' ml-auto py-[10px] px-[20px] bg-color-main-02 text-white text-[17px]'
								onClick={handleGetData}>
								{" "}
								عرض المزيد...
							</button>
						) : null}
					</div>
				</div>
			</div>
		</>
	);
};

export default PrivacyBox;
