import React from "react";
import { Header, TermsBox } from "../../components";

const Terms = () => {
	return (
		<>
			<Header />
			<TermsBox />
			<div className=' mt-[100px] md:mt-[166px] '></div>
		</>
	);
};

export default Terms;
