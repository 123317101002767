import React from "react";
import { AppStore, GooglePlay } from "../../assets/Icon";

const DownloadApp = () => {
	return (
		<div className='box-store justify-center items-center gap-[23px] mt-[61px] mb-[20px] '>
			<a
				rel='noreferrer'
				target='_blank'
				href='https://apps.apple.com/us/app/%D9%85%D9%86%D8%B5%D8%A7%D8%AA-%D8%A7%D9%84%D9%85%D8%B4%D8%A7%D9%87%D9%8A%D8%B1/id1667762906?uo=4'
				className=' md:!w-[140px]'>
				<AppStore />
			</a>
			<a
				className=' md:!w-[150px]'
				rel='noreferrer'
				target='_blank'
				href='https://play.google.com/store/apps/details?id=com.celebrate.fazIt'>
				<GooglePlay />
			</a>
		</div>
	);
};

export default DownloadApp;
