import React from "react";
import { AboutBox, Header } from "../../components";

const About = () => {
	return (
		<>
			<Header />
			<AboutBox />
			<div className=' mt-[100px] md:mt-[166px] '></div>
		</>
	);
};

export default About;
