import React from "react";

// Components
import { Header, DownloadApp } from "../../index";

// Marketing Book
import Book from "../../../assets/File/مذكـــرة المشاهيــر.pdf";

// Images
import {
	CelebrityAppImg,
	CelebrityPlatformProfile,
	InfluencerImg,
	UserInfluencerImg,
} from "../../../assets/Img";

// Icons
import {
	Download,
	Ellipse,
	Ellipse2,
	StarsOne,
	StarsTow,
} from "../../../assets/Icon";

// features For User And Merchant data
const featuresForUserAndMerchant = [
	{ id: 1, title: "ابحث عن مشهورك بسهولة وبحسب منطقتك", icon: <StarsTow /> },
	{ id: 2, title: "سهولة استخدام التطبيق", icon: <StarsTow /> },
	{ id: 3, title: "سهولة الطلب والتفاوض مع المشهور", icon: <StarsTow /> },
	{ id: 4, title: "طلبات إعلان وإهداء متنوعة ومميزة", icon: <StarsTow /> },
];

// features For Influencer data
const featuresForInfluencer = [
	{ id: 1, title: "متابعة عقود الإعلانات والطلبات", icon: <StarsOne /> },
	{ id: 2, title: "التوثيق عبر صفحات التواصل الإجتماعي", icon: <StarsOne /> },
	{ id: 3, title: "سجل الفواتير وتفاصيل كل فاتورة", icon: <StarsOne /> },
	{ id: 4, title: "معرفة رصيدك الحالي وأرباحك في المنصة", icon: <StarsOne /> },
	{
		id: 5,
		title:
			"إمكانية تسعير الإعلانات والاهداءات وطلبات المساحة الاعلانية الخاصة بك",
		icon: <StarsOne />,
	},
	{
		id: 6,
		title: "متابعة آخر الطلبات الواردة من المتابعين",
		icon: <StarsOne />,
	},
	{ id: 7, title: "إنشاء علامة تجارية خاصة بك", icon: <StarsOne /> },
	{
		id: 8,
		title: "إنشاء أكواد خصم لتحفيز المتابعين على الطلب",
		icon: <StarsOne />,
	},
	{
		id: 9,
		title: "متابعة مواعيدك المرتبطة بطلبات المتابعين",
		icon: <StarsOne />,
	},
];

const HomeBox = () => {
	return (
		<>
			<div className='overflow-hidden !overflow-y-auto'>
				{/* <!--  start-hero --> */}
				<div className='section-1  '>
					<Header />
					<div className=' flex justify-center items-start h-[1280px] md:h-[1500px]  lg:h-[920px] 2xl:h-[850px]'>
						<div className='container flex-1 h-full flex flex-col justify-center items-center'>
							<div className='hero w-full flex h-full flex-col lg:flex-row'>
								<div className='  block mt-[100px]  lg:hidden h-[500px] md:h-[700px] '>
									<img
										src={CelebrityAppImg}
										className='w-full h-full object-cover'
										alt='تطبيق المشاهير'
									/>
								</div>
								<div className='box-right flex-1 max-w-[582px] justify-start flex flex-col lg:justify-center items-start'>
									<h1 className='text-color-main-01 '>تطبيق المشاهير</h1>
									<h2>
										منصات المشاهير تطبيق يربط متجرك الالكتروني بأبرز المؤثرين.
									</h2>
									<p className='text-color-main-02 '>
										المنصة الأولى عالميا في مجال التسويق الالكتروني عبر المؤثرين
										والإعلام وإدارة الأعمال بأفكار تسويق احترافية ومبتكرة،
										لتحقيق أهدافك والوصول إلى الشهرة التي لطالما حلمت بها في
										أسرع وقت ممكن
									</p>
									<h4 className='!font-bold'>قم بتحميل التطبيق من خلال</h4>
									<DownloadApp />
								</div>
								<div className='box-left flex-1 hidden lg:block'>
									<div className='box-img   min-[1435px]:left-[-200px] '>
										<img
											src={CelebrityAppImg}
											className='w-full'
											alt='تطبيق المشاهير'
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				{/* <!--  start-features --> */}
				<div id='app' className='featuer  mt-[100px] md:mt-[168px] '>
					<div className='container flex justify-between items-center gap-[60px] lg:gap-0 flex-col lg:flex-row'>
						<div className='box-img-order  md:flex-1'>
							<div className='box-img'>
								<img
									src={UserInfluencerImg}
									alt='مميزات تطبيق المشاهير للمستخدم والتاجر'
								/>
							</div>
							<div className='box-svg top-[80px] xl:top-[120px]'>
								<Ellipse />
							</div>
						</div>
						<div className='box-left max-w-full flex-1 flex justify-center items-center'>
							<div className='box-content w-full flex flex-col justify-center  max-w-[536px] items-start gap-[30px]'>
								<div className='top bg-color-main-01 h-[96px] w-full flex justify-center items-center p-[20px] rounded-[4px]'>
									<h4 className='text-color-main-03'>
										مميزات التطبيق للمستخدم والتاجر
									</h4>
								</div>
								<ul className='box w-full flex flex-col justify-center items-center gap-[20px]   px-[18px] md:px-[20px]'>
									{featuresForUserAndMerchant?.map((item) => (
										<li
											key={item?.id}
											className='flex justify-center items-center gap-[12px] md:gap-[23px]'>
											<span>{item?.icon}</span>
											<h4 className='text-color-main-04'>{item?.title}</h4>
										</li>
									))}

									{/* Download App Component */}
									<DownloadApp />
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div className='featuer mt-[55px]'>
					<div className='container flex justify-between items-center gap-[60px] lg:gap-0 flex-col lg:flex-row'>
						<div className='box-left max-w-full flex-1 flex justify-center items-center'>
							<div className='box-content w-full flex flex-col justify-center  max-w-[536px] items-start gap-[30px]'>
								<div className='top bg-color-main-02 h-[96px] w-full flex justify-center items-center p-[20px] rounded-[4px]'>
									<h4 className='text-color-main-03 '>
										مميزات التطبيق للمشهور
									</h4>
								</div>
								<ul className='box w-full flex flex-col justify-center items-center gap-[20px] px-[18px] md:px-[20px]'>
									{featuresForInfluencer?.map((item) => (
										<li
											key={item?.id}
											className='flex justify-center items-center gap-[12px] md:gap-[23px]'>
											<span>{item?.icon}</span>
											<h4 className='text-color-main-04'>{item?.title}</h4>
										</li>
									))}

									{/* Download App Component */}
									<DownloadApp />
								</ul>
							</div>
						</div>
						<div className='box-img-order  md:flex-1'>
							<div className='box-img'>
								<img src={InfluencerImg} alt='مميزات التطبيق للمشهور' />
							</div>
							<div className='box-svg top-[80px] xl:top-[120px]'>
								<Ellipse2 />
							</div>
						</div>
					</div>
				</div>

				{/* <!--  end-features --> */}

				{/* <!-- start-book   --> */}
				<div id='book' className='book mt-[76px] '>
					<div className='container flex flex-col justify-center items-center gap-[40px] lg:gap-0 lg:flex-row'>
						<div className='box-img'>
							<img src={CelebrityPlatformProfile} alt='مذكرة المشاهير' />
						</div>
						<div className='box-content flex justify-center items-center max-w-[100%] '>
							<div className='box bg-color-main-05    px-[25px]   sm:px-[53px]  w-full max-w-[674px] flex justify-center flex-col gap-[30px]  rounded-[16px]   h-[443px] text-center items-center'>
								<h4 className='!font-[700]'>
									نقدم لك الملف التعريفي لمنصات المشاهير
								</h4>
								<a
									rel='noreferrer'
									href={Book}
									download={`الملف التعريفي لمنصات المشاهير.pdf`}
									target='_blank'
									className='w-full max-w-[398px] flex justify-center rounded-full items-center gap-[20px]  flex-row-reverse'>
									تحميل الملف الآن
									<span>
										<Download />
									</span>
								</a>
							</div>
						</div>
					</div>
				</div>

				{/* <!-- book-end   --> */}
				<div className=' mt-[100px] md:mt-[166px] '></div>
			</div>
		</>
	);
};

export default HomeBox;
